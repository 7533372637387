import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import AsyncSelect from "react-select/lib/Async";
import moment, { Moment } from "moment";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Textfit } from "react-textfit";
import { DayPickerSingleDateController } from "react-dates";
import Select from "react-select";
import {
  Input,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import debounce from "lodash.debounce";
import momentz from "moment-timezone";
import Caret from "../../images/Caret01.svg";
import { formatPhoneNumber } from "../../utils";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Diagnosis.scss";
import { DiagnosisCPTCode } from "./DiagnosisCPTcode";
import api from "../../api";
import COVID19Followup from "./COVID19Followup";
import AssessmentIntro from "./AssessmentIntro";
import ContentBlock from "./ContentBlock";
import { visitNameLookup } from "../../constants/Encounter";
import { isPCCUser } from "../../constants/Providers";
import DatePicker from "./DatePicker";
import RequsitionSelect from "./RequisitionSelect";
import CheckToggle from "../CheckToggle";
import {
  establistedPatientEMCodeChoices,
  zones,
  TimeZones,
  amPM,
  newPatientEMCodeChoices,
  quickChoices,
  quickAllergyChoices,
  requisitionVisitEMCodeChoices,
} from "../../constants/Diagnosis";
import {
  ICurrentUser,
  IEncounterDetailsResponse,
  IMedicalProfile,
  IProviderNote,
  ITestDetails,
  IUserDetails,
  IUserMedicalIntake,
  IOptionType,
  ICPTCode,
  IAssignableProviderItem,
} from "../../constants/Types";

import { RequisitionVisitTypes } from "../../constants/RequisitionTypes";

import DateAndTime from "./DateAndTime";
import InHomeHealthStatusButtons from "./InHomeHealthStatusButtons";
import DateWithRangeButtons from "./DateWithRangeButtons";
import Prescription from "./Prescription/Prescription";
import bugsnagClient from "../../services/bugsnag";

const selectStyle = {
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: isDisabled ? "#4A8FE7" : null,
    };
  },
};

export const PillList = (props) => {
  const items = props.items || [];
  if (items.length == 0) {
    return null;
  }
  const ef = () => {};
  const onRemove = props.onRemove || ef;
  const removeItem = (item) => () => {
    onRemove(item);
  };

  return (
    <div className="pill-list">
      {items.map(function (i, idx) {
        return (
          <div key={idx} className="pill d-flex">
            <Textfit mode="multi" className="align-self-center" max={14} min={8}>
              <div className="pill-content">{i.label}</div>
            </Textfit>

            <div className="close-button ml-auto" onClick={removeItem(i)}>
              {" "}
              &#x2715;{" "}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const generateSickNote = (patientName, providerName) => {
  const [fname, lname] = patientName.split(" ");
  const date = moment().format("DD-MM-YYYY");
  // prettier-ignore
  const sickNote =
 `${patientName} was seen by ${providerName} with Reliant.MD Medical
Associates for illness on ${date}. Please excuse ${fname} from work or school.
${lname} was given instructions for when they are considered to be non-infectious and
can return to work or school. Please contact us if you have any questions or concerns.`;

  return sickNote;
};

export const EmptyComponent = () => {
  return null;
};

export const stringToSelectItem = (s) => {
  return { value: s, label: s };
};
export const selectItemToString = (so) => {
  return so.label;
};

const reqOptions: IOptionType[] = [
  { value: "approved", label: "Approved" },
  { value: "declined", label: "Declined" },
  { value: "declined-by-patient", label: "Declined by patient" },
  { value: "unable-to-contact", label: "Unable to contact" },
  { value: "do-not-process", label: "Do not Process" },
];

export const GetQuickCodes = (visitType, allergySystems) => {
  if (visitType === "rash_and_allergy") {
    let eyesChoices = allergySystems.eyes ? quickAllergyChoices["eyes"] : [];
    let noseChoices = allergySystems.nose ? quickAllergyChoices["nose"] : [];
    let skinChoices = allergySystems.skin ? quickAllergyChoices["skin"] : [];
    let lungsChoices = allergySystems.lungs ? quickAllergyChoices["lungs"] : [];
    return eyesChoices.concat(noseChoices, skinChoices, lungsChoices).map(stringToSelectItem);
  } else {
    return (quickChoices[visitType] || []).map(stringToSelectItem);
  }
};

export const DiagnosisCode = ({
  visitType,
  allergySystems,
  activeConditions = [] as any[],
  diagnosisLimit = 0,
  placeholder = "",
  ...props
}) => {
  let diagnosis = (props.diagnosis || []).map(stringToSelectItem);
  const [showLimitMessage, setShowLimitMessage] = useState<boolean>(false);

  const onRemove = (item) => {
    const selected = diagnosis.filter((i: { label: string }) => i.label !== item.label);
    !!props.handleSelectChange && props.handleSelectChange(selected);
    setShowLimitMessage(false);
  };

  const onChange = (selected) => {
    if (diagnosisLimit === 0 || diagnosis.length < diagnosisLimit) {
      !!props.handleSelectChange && props.handleSelectChange(selected);
    } else {
      setShowLimitMessage(true);
    }
  };

  if (activeConditions.length > 0 && !activeConditions[0].isHeader) {
    const headerOption = {
      label: "Active Diagnoses",
      value: "Active Diagnoses",
      isHeader: true,
      isDisabled: true,
    };

    activeConditions.unshift(headerOption);
  }

  const icd10Debounced = AwesomeDebouncePromise(api.Catalog.icd10, 500);
  let loadOptions = async (text) => {
    if (text) {
      const result = await icd10Debounced(text);
      return result.items.map(stringToSelectItem);
    }
  };

  const cl =
    diagnosisLimit === 0
      ? diagnosis.length > 0
        ? "info-select diagnosis-select"
        : "info-select diagnosis-select required"
      : "";

  const quick = GetQuickCodes(visitType, allergySystems);
  const diagnosisLimitMessage = `Only ${diagnosisLimit} diagnosis codes may be added per visit. You must remove one your existing selections before adding an additional diagnosis.`;

  return (
    <Row>
      <Col md="6">
        <AsyncSelect
          placeholder={placeholder}
          isMulti
          className={cl}
          value={diagnosis}
          loadOptions={loadOptions}
          backspaceRemovesValue={false}
          isClearable={false}
          onChange={onChange}
          defaultOptions={activeConditions.length ? activeConditions : quick}
          classNamePrefix="info-select"
          noOptionsMessage={() => "Start typing to find choices"}
          components={{
            IndicatorSeparator: EmptyComponent,
            MultiValueContainer: EmptyComponent,
            DropdownIndicator: EmptyComponent,
          }}
          styles={selectStyle}
        />
        {/* <DxShortcuts current={diagnosis} onChange={props.handleSelectChange} choices={quick} /> */}
        {showLimitMessage && <p className="limitErrorMsg">{diagnosisLimitMessage}</p>}
      </Col>
      <Col md="6">
        <PillList items={diagnosis} onRemove={onRemove} />
      </Col>
    </Row>
  );
};

const isBeforeToday = (d) => {
  const earliestDate = moment().subtract(1, "days");
  return d.isBefore(earliestDate);
};

const SingleDatePicker = (props) => {
  const { disabled } = props;
  return (
    <DayPickerSingleDateController
      numberOfMonths={1}
      hideKeyboardShortcutsPanel={true}
      daySize={39}
      horizontalMonthPadding={0}
      verticalHeight={200}
      date={!disabled ? props.date : null}
      transitionDuration={0}
      isOutsideRange={!disabled ? isBeforeToday : () => true}
      onDateChange={props.onDateChange}
      onFocusChange={() => {}}
      focused={false}
    />
  );
};

const FollowUpPlan = ({ noFollowUp, ...props }) => {
  const date = props.date ? moment(props.date) : null;
  const onDateChange = (event) => {
    props.onDateChange("followUpAt", event.toISOString());
  };
  const onToggleRequired = (checked: boolean) => {
    props.onChange({ target: { name: "noFollowUp", value: checked } });
  };

  return (
    <Row className={!date && !noFollowUp ? "required" : ""}>
      <Col md="5">
        <p className="mb-1"> Recommend following up with PCP by: </p>
        <div className="d-flex flex-column">
          <p className="follow-up-date align-self-center">
            {!noFollowUp && date && date.format("MM/DD/YYYY")}
          </p>
          <SingleDatePicker disabled={noFollowUp} date={date} onDateChange={onDateChange} />
        </div>
      </Col>
      <Col md="2">
        <div className="w-100 d-flex justify-content-center">
          <small>OR</small>
        </div>
      </Col>
      <Col md="5" className="d-flex">
        <div>
          <CheckToggle checked={noFollowUp} onChange={onToggleRequired}>
            PCP follow-up not required
          </CheckToggle>
        </div>
      </Col>
    </Row>
  );
};

export const PharmacyDetails = ({ pharmacy }) => {
  const p = pharmacy;
  return (
    <div className="pharmacy-details">
      <div>{p.storeName}</div>
      <div>{p.address1} </div>
      {p.address2 && <div> {p.address2} </div>}
      <div>
        {p.city}, {p.state} {p.zipCode}
      </div>
      <div>Phone: {p.phone} </div>
      <div>Fax: {p.fax} </div>
    </div>
  );
};

export const PrescribedItems = ({ items }) => {
  return (
    <ul className="prescribed-items">
      {items.map((v, idx) => (
        <li key={idx}>
          {v.name}
          {v.strength ? `, ${v.strength}` : ""}: {v.directions}
        </li>
      ))}
    </ul>
  );
};

export const PrescriptionDetails = ({ details, pharmacies }) => {
  if (!details || !pharmacies) {
    return null;
  }
  let byPharmacy = {};
  pharmacies.forEach(
    (v) => (byPharmacy[v.pharmacyID] = details.filter((p) => p.pharmacyID === v.pharmacyID)),
  );
  return (
    <div>
      {pharmacies.map((p, idx) => (
        <div key={idx}>
          <PharmacyDetails pharmacy={p} />
          <PrescribedItems items={byPharmacy[p.pharmacyID]} />
        </div>
      ))}
    </div>
  );
};

const debounceCallback = debounce((func, param) => {
  !!func && func(param);
}, 500);

export const EncounterDate = (props) => {
  const [startDate, setStartDate] = useState("");
  const [time, setTime] = useState({ hours: "00", mins: "00", zone: "", amPM: "" });

  const handleDateChange = (d: Moment) => {
    let newDate = moment(d);
    let date = d.format("MM/DD/YYYY");
    const u = { ...time, date };
    if (newDate.isValid()) {
      onChange(u);
      setStartDate(date);
    }
  };

  const handleDateInputChange = (d: string) => {
    let date = { ...time, date: d };
    if (moment(d).isValid()) {
      onChange(date);
      setStartDate(d);
    }
  };

  useEffect(() => {
    const effectiveDate = props.effectiveDate;

    if (!!effectiveDate && !startDate) {
      try {
        const effectiveMoment = momentz.parseZone(effectiveDate);
        const date = effectiveMoment.format("MM/DD/YYYY");

        setStartDate(date);

        const hrs = effectiveMoment.format("hh");
        const min = effectiveMoment.format("mm");
        const zone = `GMT${effectiveMoment.format("Z")}`;
        const amPM = effectiveMoment.tz(TimeZones[zone]).format("A");

        const d = { ...time, hours: hrs, mins: min, amPM, zone };
        setTime(d);
      } catch (e) {}
    }
  }, [props.effectiveDate]);

  const onInputChange = (field: string) => (e: any) => {
    let v = e.target.value;
    let i = parseInt(v);

    if (!Number.isNaN(i) && v.length < 4 && v.trim() !== "") {
      if ((field === "hours" && i > 12) || (field === "mins" && i > 59)) {
        return;
      }

      if (v.length < 2) {
        v = "0" + v;
      } else {
        v = v.split("").splice(1).join("");
      }

      const u = { ...time, date: startDate, [field]: v };
      setTime(u);
      debounceCallback(onChange, u);
    }
  };

  const onSelectchange =
    (field: string) =>
    ({ value }: any) => {
      const u = { ...time, date: startDate, [field]: value };
      setTime(u);
      onChange(u);
    };
  function getTimezoneOffset() {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    let offset = new Date().getTimezoneOffset();
    const sign = offset < 0 ? "+" : "-";
    offset = Math.abs(offset);
    return `${sign}${z((offset / 60) | 0)}:${z(offset % 60)}`;
  }

  const onChange = (d) => {
    if (!d) {
      return;
    }
    d.hours = d.hours || "00";
    d.mins = d.mins || "00";
    // format the date
    const [day, month, year] = d.date.split("/");
    const z = d.zone.split("-")[1];
    let hour = d.amPM == "PM" && parseInt(d.hours) != 12 ? parseInt(d.hours) + 12 : d.hours;
    if (parseInt(hour) == 24) {
      hour = "00";
    } else if (d.amPM == "AM" && parseInt(d.hours) == 12) {
      hour = "00";
    }
    let date;
    date = `${year}-${day}-${month}T${hour}:${d.mins}-${z}`;
    if (
      props.visitType === "nursing_home" ||
      props.visitType === "blank_ltc" ||
      props.visitType === "in_home_health"
    ) {
      const zoneOffset = getTimezoneOffset();
      date = `${year}-${day}-${month}T${hour}:${d.mins}${zoneOffset}`;
    }
    props.onChange(date);
  };

  const date = moment(startDate, "MM/DD/YYYY");

  let v = zones.find((z) => z.value === time.zone);
  if (v) {
    v = { ...v, label: v.value };
  }

  let am = amPM.find((a) => a.value === time.amPM);

  if (
    props.visitType === "nursing_home" ||
    props.visitType === "blank_ltc" ||
    props.visitType === "in_home_health" ||
    props.visitType === "rx_request"
  ) {
    return (
      <DateAndTime
        onChange={onChange}
        time={time}
        setTime={setTime}
        onInputChange={onInputChange}
        handleDateChange={handleDateChange}
        effectiveDate={props.effectiveDate}
        startDate={startDate}
        handleDateInputChange={handleDateInputChange}
      />
    );
  }

  return (
    <div className="date-time">
      <div className="date">
        <DatePicker handleDateChange={handleDateChange} startDate={date} />
      </div>
      <div className="time">
        <Input
          onChange={onInputChange("hours")}
          value={time.hours}
          className="hours"
          name="hours"
          id="hours"
          placeholder="hours"
        />
        <span className="sep">:</span>
        <Input
          onChange={onInputChange("mins")}
          value={time.mins}
          className="mins"
          name="mins"
          id="mins"
          placeholder="mins"
        />
        <div className="am">
          <Select
            components={{
              IndicatorSeparator: EmptyComponent,
              MultiValueContainer: EmptyComponent,
            }}
            menuPlacement="top"
            isClearable={false}
            hideSelectedOptions={false}
            isSearchable={false}
            closeMenuOnSelect={true}
            placeholder=""
            options={amPM}
            isMulti={false}
            onChange={onSelectchange("amPM")}
            id="amPM"
            value={am}
          />
        </div>
        <div className="us-zone">
          <Select
            components={{
              IndicatorSeparator: EmptyComponent,
              MultiValueContainer: EmptyComponent,
            }}
            menuPlacement="top"
            isClearable={false}
            hideSelectedOptions={false}
            isSearchable={false}
            closeMenuOnSelect={true}
            placeholder=""
            onChange={onSelectchange("zone")}
            options={zones}
            isMulti={false}
            value={v}
            id="zone"
          />
        </div>
      </div>
    </div>
  );
};
export const checkEmcodes = (
  codes: { code: string; quantityUnits: number }[],
  loc: string = "",
  emCodes: ICPTCode[] = [],
  visitType,
): string[] => {
  let errorStrings: string[] = [];
  let i = 1;
  let dublicateErr = false;
  for (let c of codes) {
    for (let codeObj of emCodes) {
      if (codeObj.code === c.code && !codeObj[loc]) {
        let newLocations = visitType === "rx_request" ? [...locationsRx] : [...locations];
        const validLocations = locations.reduce((ac, a) => {
          if (codeObj[a.value]) {
            return [...ac, `"${a.label}""`];
          }
          return ac;
        }, [] as string[]);
        errorStrings.push(
          `Code ${c.code} may only be used when ${validLocations.join(" or ")} is selected above`,
        );
      }
    }
    if (codes.slice(i).includes(c) && !dublicateErr) {
      errorStrings.push("Please remove duplicate codes");
      dublicateErr = true;
    }
    i++;
  }
  return errorStrings;
};
export const locations = [
  { value: "telehealthVisit", label: "Video" },
  { value: "telephoneVisit", label: "Audio" },
  { value: "onSiteVisit", label: "On-site" },
];
export const locationsRx = [
  { value: "telehealthVisit", label: "Video" },
  { value: "telephoneVisit", label: "Audio" },
  { value: "asynchronous", label: "Text Message" },
  { value: "onSiteVisit", label: "On-site" },
];
type BillingBlockProps = {
  effectiveDate: string;
  note: Partial<IProviderNote>;
  visitType: string;
  onUpdate: (field: string, value: any) => void;
  missingDiagnosisFields: object;
  onEffectiveDateChange: (date: any) => void;
  onSelectProvider?: (provider: IAssignableProviderItem) => void;
  referenceID?: string | null;
  selectedProvider?: Partial<IAssignableProviderItem>;
  isScribe?: boolean;
};

export const ContentBlockBilling: FC<BillingBlockProps> = ({
  visitType,
  missingDiagnosisFields,
  note,
  onUpdate,
  onEffectiveDateChange,
  effectiveDate,
  referenceID,
  onSelectProvider,
  selectedProvider,
  isScribe,
}) => {
  const [emCodes, setEmcodes] = useState<ICPTCode[]>([]);
  const [cptCodeErrors, setCptCodeErrors] = useState<string[]>([]);

  useEffect(() => {
    api.Catalog.cptCodes().then((result) => setEmcodes(result.items));
    if (!note?.visitLocation) {
      onUpdate("visitLocation", "telehealthVisit");
    }
  }, []);

  useEffect(() => {
    setCptCodeError(checkEmcodes(note.emCodes || [], note?.visitLocation, emCodes, visitType));
  }, [emCodes]);

  const timeSpent = note?.timeSpent || 0;

  const setCptCodeError = (errors: string[]) => {
    setCptCodeErrors(errors);
    onUpdate("emCodesError", errors.length > 0); // don't send a request - just update encounter state
  };

  const onEmcodesUpdate = (codes: { code: string; quantityUnits: number }[]) => {
    setCptCodeError(checkEmcodes(codes, note?.visitLocation, emCodes));

    onUpdate("emCodes", codes);
  };

  const onLocationSelection = (loc: string) => {
    setCptCodeError(checkEmcodes(note.emCodes || [], loc, emCodes, visitType));
    onUpdate("visitLocation", loc);
  };

  const onTimeSpentChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt((event.target.value || "").trim());

    if (isNaN(value)) {
      onUpdate(event.target.name, undefined);
    } else {
      onUpdate(event.target.name, value);
    }
  };
  return (
    <>
      {(visitType === "nursing_home" ||
        visitType === "blank_ltc" ||
        visitType === "in_home_health" ||
        visitType === "rx_request") && (
        <ContentBlock title="Encounter Date & Time">
          <EncounterDate
            onChange={onEffectiveDateChange}
            effectiveDate={effectiveDate}
            visitType={visitType}
          />
        </ContentBlock>
      )}
      <div className="billing">
        <ContentBlock title="Conducted via">
          <Row>
            <Col sm="12">
              {visitType !== "online_digital_review" && visitType !== "rx_request" ? (
                <div className="visit-btns">
                  {locations.map((loc) => (
                    <Button
                      key={loc.value}
                      id={loc.value + "Btn"}
                      active={note?.visitLocation === loc.value}
                      onClick={() => onLocationSelection(loc.value)}
                    >
                      {loc.label}
                    </Button>
                  ))}
                </div>
              ) : (
                visitType === "rx_request" && (
                  <div className="visit-btns">
                    {locationsRx.map((loc) => (
                      <Button
                        key={loc.value}
                        id={loc.value + "Btn"}
                        active={note?.visitLocation === loc.value}
                        onClick={() => onLocationSelection(loc.value)}
                      >
                        {loc.label}
                      </Button>
                    ))}
                  </div>
                )
              )}
              <Row>
                <Col sm="12">
                  <ContentBlock title="Time Spent in minutes" className="inline-block">
                    <Input
                      name="timeSpent"
                      id="timeSpentInput"
                      value={timeSpent == undefined ? "" : timeSpent}
                      onChange={onTimeSpentChange}
                      placeholder=""
                    />
                  </ContentBlock>
                </Col>

                <Col sm="12">
                  <ContentBlock
                    title={"CPT Code"}
                    className="inline-block"
                    render={() => (
                      <InputError
                        message="*Please select at least one CPT code before signing"
                        flag={missingDiagnosisFields["emCodes"]}
                      />
                    )}
                  >
                    <div className="cpt-codes-wrapper">
                      <DiagnosisCPTCode
                        placeholder="Start typing code number or description"
                        onChangeCode={onEmcodesUpdate}
                        emCodes={emCodes}
                        selectedEmcodes={note?.emCodes || []}
                        cptCodeError={cptCodeErrors}
                      />
                    </div>
                  </ContentBlock>
                </Col>
              </Row>
            </Col>
          </Row>
        </ContentBlock>
        {isScribe && (
          <ContentBlock title="Provider conducting encounter">
            <ScribeSection
              referenceID={referenceID}
              onSelectProvider={onSelectProvider}
              selectedProvider={selectedProvider}
            />
          </ContentBlock>
        )}
      </div>
    </>
  );
};

type ScribeSectionProps = {
  referenceID?: string | null;
  selectedProvider?: Partial<IAssignableProviderItem>;
  onSelectProvider?: (provider: IAssignableProviderItem) => void;
};

const ScribeSection: FC<ScribeSectionProps> = ({
  referenceID = "",
  selectedProvider = {} as Partial<IAssignableProviderItem>,
  onSelectProvider,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [providers, setProviders] = useState<IAssignableProviderItem[]>([]);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    async function getList() {
      try {
        const res = await api.Providers.getAssignable(referenceID);
        setProviders(res.items || []);
      } catch (e) {}
    }

    getList();
  }, []);

  const onClick = (provider: IAssignableProviderItem) => () => {
    //setSelectedProvider(provider);
    onSelectProvider && onSelectProvider(provider);
  };
  return (
    <div className="scribe-section">
      {" "}
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className="dropdown-toggle">
          <span>{!!selectedProvider.fullName ? selectedProvider.fullName : "Select provider"}</span>
          <span>
            <Caret />
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu">
          {providers.map((item) => (
            <DropdownItem onClick={onClick(item)}>{item.fullName || ""}</DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
export const ContentBlockTreatmentPlans = ({
  visitType,
  missingDiagnosisFields,
  note,
  onUpdate,
}) => {
  const {
    treatmentPlan = "",
    isProviderConfirmed,
    providerConfirmedName,
    noNewTreatmentPlan,
  } = note;

  return (
    <ContentBlock title={visitType === "online_digital_review" ? "Plan" : "Plan/Orders"}>
      <Input
        onChange={(event) => onUpdate("treatmentPlan", event.target.value)}
        value={treatmentPlan}
        type="textarea"
        name="treatmentPlan"
        placeholder=""
      />
      {["nursing_home", "blank_ltc"].includes(visitType) && (
        <div>
          <div style={{ marginTop: 8 }}>
            <InputError
              message="* Please select one of the options below"
              flag={
                treatmentPlan.length > 0 &&
                !!missingDiagnosisFields["isProviderConfirmed"] &&
                !!missingDiagnosisFields["noNewTreatmentPlan"]
              }
            />
          </div>
          <div className={`sub-section ${treatmentPlan.length === 0 ? "disabled" : ""}`}>
            <CheckToggle
              checked={noNewTreatmentPlan}
              disabled={treatmentPlan.length === 0}
              onChange={(checked) => onUpdate("noNewTreatmentPlan", checked)}
            >
              <span className="sub-section-text">
                I acknowledge that no new orders were given this visit.
              </span>
            </CheckToggle>
          </div>
          {visitType !== "online_digital_review" && (
            <>
              <div className={`sub-section ${treatmentPlan.length === 0 ? "disabled" : ""}`}>
                <CheckToggle
                  checked={isProviderConfirmed}
                  disabled={treatmentPlan.length === 0}
                  onChange={(checked) => onUpdate("isProviderConfirmed", checked)}
                >
                  <span className="sub-section-text">
                    I acknowledge that I have verbally communicated these orders to a qualified
                    staff member at this patient’s facility.
                  </span>
                </CheckToggle>
              </div>
              <div className={`sub-section ${!isProviderConfirmed ? "disabled" : ""}`}>
                <div className="d-flex">
                  <InputError
                    message="*This field is required"
                    flag={!!missingDiagnosisFields["providerConfirmedName"] && isProviderConfirmed}
                  />
                </div>

                <Row>
                  <Col sm="6">
                    <Input
                      className={`${
                        !!missingDiagnosisFields["providerConfirmedName"] && isProviderConfirmed
                          ? "info-edit-wrapper-error"
                          : ""
                      }`}
                      name="providerConfirmedName"
                      disabled={!isProviderConfirmed}
                      value={providerConfirmedName || ""}
                      onChange={(event) => onUpdate("providerConfirmedName", event.target.value)}
                      placeholder="Type provider’s full name..."
                    />
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      )}
    </ContentBlock>
  );
};

export const InputError = ({ message, flag }) => (
  <h1 className="input-error">{flag ? message : ""}</h1>
);

type DiagnosisProps = {
  saveAdditionalStatus: Function;
  profile: Partial<IMedicalProfile>;
  effectiveDate: string;
  currentUser?: ICurrentUser | null;
  prescriptionError: string;
  note: Partial<IProviderNote>;
  details: Partial<IEncounterDetailsResponse>;
  patient: IUserDetails;
  testResult?: ITestDetails;
  visitType: string;
  encounterID: string;
  intake: IUserMedicalIntake;
  noteLoaded?: boolean;
  onUpdate: (field: string, value: any) => void;
  onPrescriptionLink: () => void;
  isExistingUser?: boolean;
  isNursingHomeSite: boolean;
  missingDiagnosisFields: object;
  onEffectiveDateChange: (date: any) => void;
  onSelectProvider: (provider: IAssignableProviderItem) => void;
  myref: React.RefObject<HTMLDivElement>;
  requisitionStatus: (status: { status: string[] }) => void;
  referenceID?: string;
  selectedProvider?: Partial<IAssignableProviderItem>;
  isScribe: boolean;
  onPrescriptionValidated: Function;
  chiefComplaint: string;
};

const Diagnosis: FC<DiagnosisProps> = (props) => {
  const [activeConditions, setActiveConditions] = useState<IOptionType[]>([]);
  const [visitOutcome, setVisitOutcome] = useState<string>("");
  const { note, isExistingUser, missingDiagnosisFields, prescriptionError, visitType } = props;
  const { followUpAt, diagnosis, noFollowUp } = note;
  const status =
    props.details && props.details.additionalStatus
      ? reqOptions.find((status) => status.value === props.details.additionalStatus![0])
      : { value: "", label: "" };
  const providerName = props.currentUser
    ? `${props.currentUser.firstName} ${props.currentUser.lastName}`
    : "";

  const patientName = props.patient.name;
  const sickNote = note.sickNote || "";
  const assessment = note.assessment || "";
  let otherNotes = note.otherNotes || "";

  let emCodeOptions: IOptionType[] = [];
  emCodeOptions = isExistingUser ? establistedPatientEMCodeChoices : newPatientEMCodeChoices;
  // add requisition visit specific codes
  if (RequisitionVisitTypes.includes(visitType)) {
    emCodeOptions.push(...requisitionVisitEMCodeChoices);
  }

  const emCode = emCodeOptions.find((option) => option.value === String(note.emCode));
  const errorMessage = "*This field is required";
  const noneSelectedError = "*No followup items selected";
  const assessmentTitle =
    visitType === "in_home_health" || visitType === "rx_request"
      ? "Assessment and Plan"
      : "Assessment";

  const visitOutcomeChange = async (value) => {
    try {
      await api.Encounters.postVisitOutcome(props.referenceID, value);
    } catch (error) {
      bugsnagClient.notify(error);
    }
  };
  const debouncedChangeHandler = useCallback(debounce(visitOutcomeChange, 500), []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setVisitOutcome(value);
    debouncedChangeHandler(value);
  };

  const pharmacyID = props?.profile?.preferredPharmacy?.pharmacyID;
  const pharmacyName = props?.profile?.preferredPharmacy?.storeName;
  const pharmacyAddress = props?.profile?.preferredPharmacy?.address1;
  const pharmacyIndex = props?.profile?.preferredPharmacy?.zip;
  const pharmacyState = props?.profile?.preferredPharmacy?.state;
  const pharmacyCity = props?.profile?.preferredPharmacy?.city;
  const pharmacyPhone = formatPhoneNumber(props?.profile?.preferredPharmacy?.primaryPhone);

  useEffect(() => {
    
    if (visitType !== "nursing_home") {
      const sickNote = generateSickNote(patientName, providerName);
      props.onUpdate("sickNote", sickNote);
    }

    async function fetchVisitOutcome() {
      try {
        const fetchedVisitOutcome = await api.Encounters.getVisitOutcome(props.referenceID);
        setVisitOutcome(fetchedVisitOutcome.visitOutcome);
      } catch (error) {
        bugsnagClient.notify(error);
      }
    }
    //get patientConditions
    async function fetchConditions() {
      const patientID = props.patient.userID;
      const results = await api.PCC.conditions(patientID);
      const conditions = (results.conditions || []).reduce(
        (arr: IOptionType[], { icd10, icd10Description }) => {
          return [
            ...arr,
            { value: `${icd10} ${icd10Description}`, label: `${icd10} ${icd10Description}` },
          ];
        },
        [],
      );

      setActiveConditions(conditions);
    }

    if (isPCCUser(props.currentUser)) {
      // only fetch conditions if a PCC is active
      fetchConditions();
    }
    fetchVisitOutcome();
  }, []);

  const handleDiagnosisChange = (selectedOptions) => {
    if (selectedOptions.length < 1) {
      props.onUpdate("diagnosis", null);
    } else {
      props.onUpdate("diagnosis", selectedOptions.map(selectItemToString));
    }
  };

  const handleChange = (event) => {
    props.onUpdate(event.target.name, event.target.value);
  };

  const handleDateChange = (name, newDate) => {
    props.onUpdate(name, newDate);
  };

  if (
    visitType === "nursing_home" ||
    visitType === "rxrenewal" ||
    visitType === "in_home_health" ||
    visitType === "rx_request"
  ) {
    return (
      <div className="patient-diagnosis">
        <ContentBlock title={assessmentTitle}>
          <Input
            onChange={handleChange}
            value={assessment}
            type="textarea"
            name="assessment"
            id="assessment"
            placeholder="Type any additional notes, assessment, diagnosis, or treatment, information..."
          />
        </ContentBlock>
        {missingDiagnosisFields["diagnosis"] && <div ref={props.myref} />}
        <ContentBlock
          title="Diagnosis"
          render={() => (
            <InputError
              message="*Please select at least one diagnosis code for this encounter before signing"
              flag={missingDiagnosisFields["diagnosis"] || diagnosis === null}
            />
          )}
        >
          <DiagnosisCode
            placeholder="Start typing diagnosis or ICD10 code..."
            diagnosis={diagnosis}
            activeConditions={activeConditions}
            handleSelectChange={handleDiagnosisChange}
            visitType={visitType}
            allergySystems={props.intake.allergySystems}
            diagnosisLimit={visitType !== "rxrenewal" ? 5 : 0}
          />
        </ContentBlock>
        {visitNameLookup[visitType] === "Coronavirus" && (
          <ContentBlock
            title="Coronavirus (COVID-19) Consult Visit Treatment Plan"
            render={() => (
              <InputError
                message={note.coronavirusFollowup !== undefined ? noneSelectedError : errorMessage}
                flag={missingDiagnosisFields["coronavirusFollowup"]}
              />
            )}
          >
            <COVID19Followup
              note={note}
              onUpdate={props.onUpdate}
              error={missingDiagnosisFields["coronavirusFollowup"]}
            />
          </ContentBlock>
        )}
        {visitType === "in_home_health" && (
          <ContentBlock
            title="Visit Outcome(s)"
            render={() => (
              <InputError
                message="*Please select at least one additional status for this encounter before signing"
                flag={
                  missingDiagnosisFields["additionalStatus"] ||
                  props.details.additionalStatus === []
                }
              />
            )}
          >
            <InHomeHealthStatusButtons
              status={props.details.additionalStatus || []}
              onChangeStatus={(status) => props.saveAdditionalStatus(status)}
            />
          </ContentBlock>
        )}
        
        {visitType !== "rx_request" && visitType !== "nursing_home" && (
          <>
            <ContentBlock title="Sick Note For Patient">
              <Input onChange={handleChange} value={sickNote} type="textarea" name="sickNote" />
            </ContentBlock>
            <ContentBlock
              title={isExistingUser ? "Established Patient E/M Code" : "New Patient E/M Code"}
              render={() => (
                <InputError message={errorMessage} flag={missingDiagnosisFields["emCode"]} />
              )}
            >
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                inputId={"em-select"}
                hideSelectedOptions={true}
                isSearchable={false}
                className={
                  missingDiagnosisFields["emCode"] ? "info-select error w-50" : "info-select w-50"
                }
                placeholder={"Choose E/M Code"}
                options={emCodeOptions}
                value={emCode}
                onChange={(selected: any) => props.onUpdate("emCode", selected.value)}
              />
            </ContentBlock>
          </>
        )}
        {visitType === "rx_request" ? (
          <ContentBlock
            title="Prescription"
            render={() => (
              <InputError
                message={
                  "*You must either write a prescription or select prescription denied to complete this visit"
                }
                flag={missingDiagnosisFields["prescriptionStatus"]}
              />
            )}
          >
            <Prescription
              note={note}
              onPrescriptionLink={props.onPrescriptionLink}
              onPrescriptionValidated={props.onPrescriptionValidated}
              currentUser={props.currentUser}
              patient={props.patient}
              onNoteUpdated={props.onUpdate}
              saveAddtionalStatus={props.saveAdditionalStatus}
              details={props.details}
              chiefComplaint={props.chiefComplaint}
              encounterID={props.referenceID}
            />
          </ContentBlock>
        ) : (
          <ContentBlock title="Prescription">
            <Button
              className="prescribe-button"
              onClick={props.onPrescriptionLink}
              color="primary"
              outline
            >
              Write Prescription
            </Button>
            {prescriptionError && (
              <p className="prescriptionError">DoseSpot Error: {prescriptionError}</p>
            )}
            <PrescriptionDetails
              details={note.prescriptionDetails}
              pharmacies={note.pharmacyDetails}
            />
          </ContentBlock>
        )}
        {visitType === "rx_request" && (
          <ContentBlock title={"Follow Up Plan"}>
            <DateWithRangeButtons
              onChange={(value) => props.onUpdate("followUpAt", value)}
              effectiveDate={note.followUpAt}
            />
          </ContentBlock>
        )}
        {visitType !== "in_home_health" && visitType !== "rx_request" && (
          <ContentBlockTreatmentPlans
            visitType={props.visitType}
            missingDiagnosisFields={missingDiagnosisFields}
            note={note}
            onUpdate={props.onUpdate}
          />
        )}
        <ContentBlockBilling
          isScribe={props.isScribe}
          referenceID={props.referenceID}
          onSelectProvider={props.onSelectProvider}
          selectedProvider={props.selectedProvider}
          visitType={props.visitType}
          missingDiagnosisFields={missingDiagnosisFields}
          note={note}
          onUpdate={props.onUpdate}
          onEffectiveDateChange={props.onEffectiveDateChange}
          effectiveDate={props.effectiveDate}
        />
        {missingDiagnosisFields["emCode"] && !missingDiagnosisFields["diagnosis"] && (
          <div ref={props.myref} />
        )}
      </div>
    );
  }

  return (
    <div className="patient-diagnosis">
      <ContentBlock title="Assessment">
        {!props.isNursingHomeSite && (
          <AssessmentIntro
            testResult={props.testResult}
            patient={props.patient}
            profile={props.profile}
            intake={props.intake}
            onChange={handleChange}
          />
        )}
        <Input
          onChange={handleChange}
          value={assessment}
          type="textarea"
          className={assessment === "" ? "required" : ""}
          name="assessment"
          id="assessment"
        />
      </ContentBlock>
      <ContentBlock title="Other Notes">
        <Input onChange={handleChange} value={otherNotes} type="textarea" name="otherNotes" />
      </ContentBlock>
      <ContentBlock title="Diagnosis">
        <DiagnosisCode
          placeholder="Start typing diagnosis or ICD10 code..."
          diagnosis={diagnosis}
          activeConditions={[]}
          handleSelectChange={handleDiagnosisChange}
          visitType={visitType}
          allergySystems={props.intake.allergySystems}
        />
      </ContentBlock>
      {visitNameLookup[visitType] === "Coronavirus" && (
        <ContentBlock
          title="Coronavirus (COVID-19) Consult Visit Treatment Plan"
          render={() => (
            <InputError
              message={note.coronavirusFollowup !== undefined ? noneSelectedError : errorMessage}
              flag={missingDiagnosisFields["coronavirusFollowup"]}
            />
          )}
        >
          <COVID19Followup
            note={note}
            onUpdate={props.onUpdate}
            error={missingDiagnosisFields["coronavirusFollowup"]}
          />
        </ContentBlock>
      )}
      <ContentBlock title="Prescription">
        <p style={{ fontSize: 12 }}>
          <span>Patient-Selected Pharmacy - </span>
          {!!pharmacyID
            ? `${pharmacyName} - ${pharmacyID} ${pharmacyAddress}, ${pharmacyCity}, ${pharmacyState}, ${pharmacyIndex} - ${pharmacyPhone}`
            : "None"}
        </p>
        <Button
          className="prescribe-button"
          onClick={props.onPrescriptionLink}
          color="primary"
          outline
        >
          Write Prescription
        </Button>
        <PrescriptionDetails details={note.prescriptionDetails} pharmacies={note.pharmacyDetails} />
      </ContentBlock>

      <ContentBlock title="Sick Note For Patient">
        <Input onChange={handleChange} value={sickNote} type="textarea" name="sickNote" />
      </ContentBlock>
      <ContentBlock
        title={isExistingUser ? "Established Patient E/M Code" : "New Patient E/M Code"}
        render={() => <InputError message={errorMessage} flag={missingDiagnosisFields["emCode"]} />}
      >
        <Select
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          inputId={"em-select"}
          hideSelectedOptions={true}
          isSearchable={false}
          className={
            missingDiagnosisFields["emCode"] ? "info-select error w-50" : "info-select w-50"
          }
          placeholder={"Choose E/M Code"}
          options={emCodeOptions}
          value={emCode}
          onChange={(selected: any) => props.onUpdate("emCode", selected.value)}
        />
      </ContentBlock>
      {!!visitType && RequisitionVisitTypes.includes(visitType.toLowerCase()) && (
        <ContentBlock
          title="Requisition Status"
          render={() => (
            <InputError message={errorMessage} flag={missingDiagnosisFields["additionalStatus"]} />
          )}
        >
          <RequsitionSelect
            reqOptions={reqOptions}
            status={status}
            missingDiagnosisFields={missingDiagnosisFields}
            requisitionStatus={props.requisitionStatus}
          />
        </ContentBlock>
      )}
      <ContentBlock title="Follow Up Plan">
        <FollowUpPlan
          date={followUpAt}
          onDateChange={handleDateChange}
          onChange={handleChange}
          noFollowUp={noFollowUp}
        />
      </ContentBlock>

      <div ref={props.myref} />
    </div>
  );
};

export default Diagnosis;

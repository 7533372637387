import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Input,
} from "reactstrap";

import api from "../api";
import bugsnagClient from "../services/bugsnag";
import { SWITCH_PRACTICE, ADD_NURSING_HOME_FLAG } from "../constants/actionTypes";
import { STATES } from "../constants/Providers";

import Magnifying from "../images/SearchMagnifying.svg";
import Arrow from "../images/menuarrow.svg";
import styles from "./PracticeSelector.scss";

const getPracticeLabel = (option) => option.name;

function PracticeSelector({ currentPracticeID, onSwitchPractice, currentUser, ...props }) {
  const [practices, setPractices] = useState({});
  const [allPractices, setAllPractices] = useState([]);

  const [mainDropdownOpen, setMainDropdownOpen] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await api.Providers.listPractices();
        setPractices(result.items);
        let practices = [];

        Object.keys(result.items).map((s) => practices.push(...result.items[s]));

        setAllPractices(practices);
      } catch (error) {
        bugsnagClient.notify(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    //fallback when isNursingHomeSite flag is not set
    //mostly happens when user manually refresh the page
    if (currentUser && practices.length > 0) {
      const keys = Object.keys(practices);
      for (let i = 0; i < keys.length; i++) {
        if (practices[keys[i]].id === currentUser.currentPracticeID) {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          props.addNursingHomeFlag(practices.isNursingHomeSite);
          break;
        }
      }
    }
    setQuery("");
    setMainDropdownOpen(false);
  }, [currentUser, practices]);

  const currentPractice = allPractices.find((i) => i.id === currentPracticeID);

  const mainDropdownToggle = () => {
    setQuery("");
    setMainDropdownOpen(!mainDropdownOpen);
  };

  if (allPractices.length == 1) {
    return <div>{allPractices[0].name}</div>;
  }

  const StatePracticesList = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const statePracticesListToggle = () => {
      setDropdownOpen(!dropdownOpen);
    };

    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={statePracticesListToggle}
        direction="right"
        style={{ width: "100%", padding: 0 }}
      >
        <DropdownToggle tag="div" className="d-flex w-100" style={{ cursor: "pointer" }}>
          <span className="title">{STATES[props.name]}</span>
          <span className="icon" style={{ width: "100%", textAlign: "right" }}>
            <Arrow style={{ position: "relative", top: "5px" }} />
          </span>
        </DropdownToggle>
        <DropdownMenu className="menu" style={{ marginLeft: "40px", backgroundColor: "#f6f6f6" }}>
          {props.practices.map((p) => {
            if (currentPracticeID && currentPracticeID == p.id) {
              return;
            }
            return (
              <DropdownItem
                key={p.id}
                toggle={false}
                tag="div"
                className={`${styles.submenuItem} qa-practiceSelectorState`}
                onClick={() => {
                  // only switch the practice if the practice selection has changed
                  if (currentUser.currentPracticeID != p.id) {
                    onSwitchPractice(p.id);
                  }
                  setDropdownOpen(false);
                }}
              >
                {p.name}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  };

  const PracticesList = () => {
    // render the search results first
    if (query !== "") {
      return (
        allPractices
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
          .filter((p) => p.name.toLowerCase().includes(query.toLowerCase()))
          .map((p) => (
            <DropdownItem
              // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
              key={p.id}
              toggle={false}
              tag="div"
              className={`${styles.textOverflow} qa-practiceSelectorPracticeName`}
              onClick={() => {
                // only switch the practice if the practice selection has changed
                if (currentUser.currentPracticeID != p.id) {
                  onSwitchPractice(p.id);
                }
                setMainDropdownOpen(false);
              }}
            >
              <span title={p.name}>{p.name}</span>
            </DropdownItem>
          ))
      );
    }

    // check if practices are in different states, if yes construct states dropdowns first
    if (Object.keys(practices).length > 1) {
      return Object.keys(practices).map((state) => {
        if (
          practices[state].length <= 1 &&
          practices[state].find((item) => item.id === currentPracticeID)
        ) {
          return null;
        }
        return (
          <DropdownItem key={state} className={styles.submenuItem} toggle={false} tag="div">
            <StatePracticesList name={state} practices={practices[state]} />
          </DropdownItem>
        );
      });
    }

    // default to listing all practices
    return allPractices.map((p) => (
      <DropdownItem
        key={p.id}
        toggle={false}
        tag="div"
        className={`${styles.submenuItem} "qa-practiceSelectorAllPractices" ${styles.textOverflow}`}
        onClick={() => {
          // only switch the practice if the practice selection has changed
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
          if (currentUser.currentPracticeID != p.id) {
            onSwitchPractice(p.id);
          }
          setMainDropdownOpen(false);
        }}
      >
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'. */}
        <span title={p.name}>{p.name}</span>
      </DropdownItem>
    ));
  };
  return (
    <div className="main-select">
      <Dropdown isOpen={mainDropdownOpen} toggle={mainDropdownToggle} direction="down">
        <DropdownToggle tag="div">
          <Select
            value={currentPractice}
            isLoading={allPractices.length === 0}
            className="practiceSelector, qa-practiceSelector"
            styles={{
              menu: (base) => ({ ...base, zIndex: 50 }),
              backgroundColor: "#F6F6F6",
            }}
            getOptionLabel={getPracticeLabel}
            isSearchable={false}
            backspaceRemovesValue={false}
            closeMenuOnSelect={false}
            noOptionsMessage={() => null}
          />
        </DropdownToggle>

        <DropdownMenu className={styles.menu} tag="div">
          <InputGroup
            className="d-flex"
            style={{
              paddingTop: "5px",
              paddingLeft: "8%",
              paddingBottom: "10px",
            }}
          >
            <Input
              className={`${styles.textOverflow} qa-practiceSelectorSearchBar`}
              placeholder="Search practices"
              onChange={(e) => setQuery(e.target.value)}
              style={{ borderRadius: ".25rem" }}
            />
            <Magnifying className={styles.magnifyingGlass} />
          </InputGroup>

          <PracticesList />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentPracticeID: state.common.currentUser ? state.common.currentUser.currentPracticeID : "",
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onSwitchPractice: (practiceID) =>
    dispatch({
      type: SWITCH_PRACTICE,
      payload: api.Authorization.switchPractice(practiceID),
    }),
  addNursingHomeFlag: (isNursingHomeSite) =>
    dispatch({
      type: ADD_NURSING_HOME_FLAG,
      isNursingHomeSite,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeSelector);

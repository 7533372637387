import React, { useState, useEffect, useCallback, FC } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { FormGroup, Input, Button, Table, Badge } from "reactstrap";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

import NavBar from "../Provider/NavBar";
import { store } from "../../store";
import Spinner from "../../images/Spinner.svg";
import api from "../../api";
import {
  COMPONENT_LOAD,
  COMPONENT_UNLOAD,
  ENCOUNTER_MONITOR_LIST,
  ENCOUNTERS_SEARCH_RESULTS,
  NAVIGATE_TO,
  SELECT_PRACTICE,
  SWITCH_PRACTICE,
  LOAD_ENCOUNTERS_DETAILS
} from "../../constants/actionTypes";
import { defaultColumns, defaultParams } from "../../constants/Encounter";

import "../../stylesheets/encounterMonitor.scss";

import { IEncounterListItem, IlistPractice, DefaultSearchParams } from "../../constants/Types";
import EncounterSearch from "../Patients/EncounterSearch/EncounterSearch";

import Chat from "../../images/MonitorChat.svg";
import Video from "../../images/MonitorVideo.svg";
import PaginationAE from "./PaginationBlock";
import CustomizeTableModal from "./TableModal";
import MonitorEncounterFilter from "./MonitorEncounterFilter";

import Octicon, { TriangleUp, TriangleDown } from "@githubprimer/octicons-react";
import { secondsToMinutes } from "../../utils";

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    permissions: state.common.permissions,
    selectedPractice: state.monitoring.selectedPractice || {},
    ...state.componentData[ENCOUNTERS_SEARCH_RESULTS],
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload, queryData) =>
    dispatch({
      type: COMPONENT_LOAD,
      subtype: ENCOUNTERS_SEARCH_RESULTS,
      payload,
      targetPath: `/encounter-monitor/${queryData}`,
    }),
  clearEncounterSearchResults: () =>
    dispatch({
      type: COMPONENT_UNLOAD,
      subtype: ENCOUNTERS_SEARCH_RESULTS,
    }),
  onUnload: () => dispatch({ type: COMPONENT_UNLOAD, subtype: ENCOUNTERS_SEARCH_RESULTS }),
  viewEncounter: (id) =>
    dispatch({ type: NAVIGATE_TO, targetPath: `/encounter-monitor/${id}` }),
  setSelectedPractice: (practice: { name: string; id: string; nhFlag: boolean }) =>
    dispatch({ type: SELECT_PRACTICE, practice }),
  onSwitchPractice: (practiceID: string, id: string) =>
    dispatch({
      type: SWITCH_PRACTICE,
      subtype: LOAD_ENCOUNTERS_DETAILS,
      payload: api.Authorization.switchPractice(practiceID),
      noRedirect: true,
      // targetPath: `/encounter-monitor/${id}`,
    }),
});

export const formatTime = (lastContact: string) => {
  
  const time = moment.utc(lastContact);
  if (!time.isValid()) {
    return "";
  }
  let d = moment.duration(moment().diff(time)).asHours();

  if (d < 1) {
    let mins = Math.round(moment.duration(moment().diff(time)).asMinutes());
    return `(${mins} min ago)`;
  } else if (d < 48) {
    let hours = Math.round(moment.duration(moment().diff(time)).asHours());
    return `(${hours} hours ago)`;
  } else {
    let days = Math.round(moment.duration(moment().diff(time)).asDays());
    return `(${days} days ago)`;
  }
};

type Props = {
  encounter: IEncounterListItem;
  onClick: (string, practiceID?: string) => (event: MouseEvent) => void;
  currentView: string;
  shownColumns: string[];
};

export const EncounterRow: FC<Props> = ({ encounter, onClick, currentView, shownColumns }) => {
  const visitCreated = moment(encounter?.timestamp).format("MM/DD/YY h:mma");
  const prescribeDate = encounter?.prescribedDate
    ? moment(encounter?.prescribedDate).format("MM/DD/YYYY hh:mm A")
    : "--";
  const scribeDate = encounter?.scribedDate
    ? moment(encounter.scribedDate).format("MM/DD/YYYY hh:mm A")
    : "--";
  const signedDate = encounter?.signedDate
    ? moment(encounter.signedDate).format("MM/DD/YYYY hh:mm A")
    : "--";

  const lastContact = moment.utc(encounter.lastContact || "").isValid()
    ? moment
        .utc(encounter.lastContact || "")
        .local()
        .format("MM/DD/YY hh:mma")
    : "N/A";

  const durationLastContact = encounter?.lastContact && formatTime(encounter.lastContact);
  const durationPrescribeDate = encounter?.prescribedDate && formatTime(encounter.prescribedDate);
  const durationScribeDate = encounter?.scribedDate && formatTime(encounter.scribedDate);
  const durationSignedDate = encounter?.signedDate && formatTime(encounter.signedDate);

  const isChat = encounter.communicationMethod === "Chat";
  const patientLeftWaitingRoom = !!encounter?.encounterStats?.patientExitedWaitingRoomPrematured
    ? "Yes"
    : "No";
  const patientEnteredWaitingRoom = !!encounter?.encounterStats?.neverEnteredWaitingRoom
    ? "No"
    : "Yes";

  const patientWaitingTime =
    encounter?.encounterStats?.patientWaitedTime && !isChat
      ? secondsToMinutes(encounter.encounterStats.patientWaitedTime)
      : "--";

  const waitingTimeClass =
    encounter?.status &&
    encounter?.status === "postponed" &&
    encounter?.encounterStats?.patientWaitedTime
      ? "Postponed"
      : encounter?.status &&
        encounter?.status === "available" &&
        encounter?.encounterStats?.patientWaitedTime
      ? "Available"
      : encounter?.status;
  const connectionAttempts =
    !!encounter?.encounterStats?.patientNumOfAttempts && encounter.communicationMethod === "Video"
      ? encounter?.encounterStats?.patientNumOfAttempts
      : "--";

  const videoTime = encounter?.encounterStats?.providerVideoChatTime
    ? secondsToMinutes(encounter?.encounterStats?.providerVideoChatTime)
    : "--";
  const videoTimeClass =
    encounter?.encounterStats?.providerVideoChatTime &&
    encounter?.encounterStats?.providerVideoChatTime < 180
      ? "alert-time"
      : "";

  const viaFormulary = !!encounter?.viaFormulary
  ? "Yes"
  : "No";
  
  const followUp = !!encounter?.followUp
  ? "Yes"
  : "No";
  
  const billedInsurance = !!encounter?.billedInsurance
  ? "Yes"
  : "No";      

  return (
    <tr onClick={onClick(encounter.referenceID, encounter.practiceID)} className="encounterRow">
      {shownColumns.includes("timestamp") && (
        <td className="encounter-monitor-table-dates">{visitCreated}</td>
      )}
      {shownColumns.includes("patientName") && <td>{encounter.patientName || "--"}</td>}
      {shownColumns.includes("location") && <td>{encounter.location || "--"}</td>}
      {shownColumns.includes("patientSex") && <td>{encounter.patientSex}</td>}
      {shownColumns.includes("patientAge") && <td>{encounter.patientAge}</td>}
      {shownColumns.includes("patientLanguage") && <td>{encounter.patientLanguage}</td>}
      {shownColumns.includes("patientReliantID") && <td>{encounter.patientReliantID}</td>}
      {shownColumns.includes("patientPCCID") && <td>{encounter.patientPCCID}</td>}
      {shownColumns.includes("providerName") && <td>{encounter.providerName || "--"}</td>}
      {shownColumns.includes("extender") && <td>{encounter.extender}</td>}
      {shownColumns.includes("scribe") && <td>{encounter.scribe}</td>}      
      {shownColumns.includes("medicalPracticeName") && <td>{encounter.medicalPracticeName}</td>}
      {shownColumns.includes("chiefComplaint") && <td>{encounter.chiefComplaint || "--"}</td>}
      {/* Add style to color if visit it postponed */}
      {shownColumns.includes("patientWaitedTime") && (
        <td>
          <div className={waitingTimeClass}>{patientWaitingTime}</div>
          {/* <div className="waiting">({waitingTimeClass})</div> */}
        </td>
      )}
      {shownColumns.includes("communicationMethod") && (
        <td>
          {isChat ? (
            <Chat />
          ) : encounter.communicationMethod === "Video" ? (
            <Video />
          ) : (
            <span>--</span>
          )}
        </td>
      )}
      {shownColumns.includes("latestVisitStatus") && <td>{encounter.latestVisitStatus}</td>}
      {shownColumns.includes("status") && <td>{encounter.status}</td>}
      {shownColumns.includes("newMessages") && (
        <td className="monitorNewMessages qa-monitorNewMessages">
          {!!isChat && encounter.newMessages === "Yes" ? (
            <Badge pill color="info" className="monitorNewMessagesBadge">
              {encounter.newMessages}
            </Badge>
          ) : !!isChat && encounter.newMessages === "No" ? (
            <span>{encounter.newMessages}</span>
          ) : (
            <span>--</span>
          )}
        </td>
      )}
      {shownColumns.includes("lastContact") && (
        <td>
          <div className="last-contact">{lastContact}</div>
          <div className="time-elapsed">{durationLastContact}</div>
        </td>
      )}
      {shownColumns.includes("prescribedDate") && (
        <td>
          <div className="prescribe-date">{prescribeDate}</div>
          <div className="time-elapsed">{durationPrescribeDate}</div>
        </td>
      )}
      {shownColumns.includes("pharmacyName") && <td>{encounter.pharmacyName}</td>}
      {shownColumns.includes("medicationPerscribed") && <td>{encounter.medicationPerscribed}</td>}
      {shownColumns.includes("prescriptionRXCUI") && <td>{encounter.prescriptionRXCUI}</td>}
      {shownColumns.includes("viaFormulary") && <td>{viaFormulary}</td>}
      {shownColumns.includes("scribeDate") && (
        <td>
          <div className="scribe-date">{scribeDate}</div>
          <div className="time-elapsed">{durationScribeDate}</div>
        </td>
      )}
      {shownColumns.includes("signedDate") && (
        <td>
          <div className="signed-date">{signedDate}</div>
          <div className="time-elapsed">{durationSignedDate}</div>
        </td>
      )}
      {shownColumns.includes("patientNumOfAttempts") && <td>{connectionAttempts}</td>}
      {shownColumns.includes("neverEnteredWaitingRoom") && <td>{patientEnteredWaitingRoom}</td>}
      {shownColumns.includes("patientExitedWaitingRoomPrematured") && (
        <td>{patientLeftWaitingRoom}</td>
      )}
      {shownColumns.includes("providerVideoChatTime") && (
        <td className={videoTimeClass}>{videoTime}</td>
      )}
      {shownColumns.includes("totalActiveChartingTime") && <td>{encounter.totalActiveChartingTime}</td>}
      {shownColumns.includes("encounterId") && (
        <td className="encounterID-col">{encounter.referenceID}</td>
      )}
      {shownColumns.includes("organizationType") && <td>{encounter.organizationType}</td>}
      {shownColumns.includes("organization") && <td>{encounter.organization}</td>}
      {shownColumns.includes("clinicalServicesLocation") && <td>{encounter.clinicalServicesLocation}</td>}
      {shownColumns.includes("healthGorillaSessions") && <td>{encounter.healthGorillaSessions}</td>}
      {shownColumns.includes("followUp") && <td>{followUp}</td>}
      {shownColumns.includes("visitType") && <td>{encounter.visitType}</td>}
      {shownColumns.includes("chargeAmountToPatient") && <td>{encounter.chargeAmountToPatient}</td>}
      {shownColumns.includes("promoCodeUsed") && <td>{encounter.promoCodeUsed}</td>}
      {shownColumns.includes("patientCopay") && <td>{encounter.patientCopay}</td>}
      {shownColumns.includes("billedInsurance") && <td>{billedInsurance}</td>}
      {shownColumns.includes("patientPrimaryPayer") && <td>{encounter.patientPrimaryPayer}</td>}
      {shownColumns.includes("visitBillingLocation") && <td>{encounter.visitBillingLocation}</td>}
      {shownColumns.includes("CPTCode") && <td>{encounter.CPTCode}</td>}
      {shownColumns.includes("ICD10") && <td>{encounter.ICD10}</td>}
      {shownColumns.includes("suggestedFollowUpDate") && <td>{encounter.suggestedFollowUpDate}</td>}
      {shownColumns.includes("effectiveDate") && <td>{encounter.effectiveDate}</td>}
      {shownColumns.includes("outcome") && <td>{encounter.outcome}</td>}
    </tr>
  );
};

const VIEW_RECENT_ENCOUNTER = "recentEncounter";
const VIEW_AT_HOME_PCR_TEST_KITS = "atHomePCRTestKits";

const TestKitRow = ({ encounter, currentUser }) => {
  const vd = moment(encounter.date);
  let badgeColor;
  switch (encounter.status.toLowerCase()) {
    case "complete":
      badgeColor = "success";
      break;
    case "pending":
      badgeColor = "warning";
      break;
    default:
      badgeColor = "warning";
  }

  const viewUser = (userID) => {
    store.dispatch(push(`/patients/${userID}`));
  };

  if (!!currentUser && currentUser?.currentPracticeID === encounter?.practiceID) {
    return (
      <tr>
        <td>{vd.format("MM/DD/YY")}</td>
        <td>
          <Button
            color="link"
            className="patient-link qa-monitorPatientLink"
            onClick={() => viewUser(encounter.userID)}
          >
            {encounter.name}
          </Button>
        </td>
        <td>{encounter.barcode}</td>
        <td className="encounter-status-cell">
          <Badge color={badgeColor} className="qa-monitorEncounterStatus">
            {encounter.status.toLowerCase() !== "complete" ? "Pending" : "Complete"}
          </Badge>
        </td>
        <td>&nbsp;</td>
      </tr>
    );
  } else {
    return <></>;
  }
};

const Sorter = ({ field, handleSort, sortType, label }) => {
  return (
    <div className="sort-header">
      <div className="sort-label">{label}</div>
      <span className="sort">
        <span
          className={
            sortType.field === field && sortType.order === "ascending"
              ? "sort-up sort-active "
              : "sort-up"
          }
          onClick={() => handleSort(field, "ascending")}
        >
          <Octicon icon={TriangleUp} size={12} />
        </span>
        <span
          className={
            sortType.field === field && sortType.order === "descending"
              ? "sort-down sort-active "
              : "sort-down"
          }
          onClick={() => handleSort(field, "descending")}
        >
          <Octicon icon={TriangleDown} size={12} />
        </span>
      </span>
    </div>
  );
};

function Monitor({
  onLoad,
  loadQueries,
  onUnload,
  clearEncounterSearchResults,
  currentUser,
  viewEncounter,
  data,
  inProgress,
  selectedPractice,
  onSwitchPractice,
  ...props
}) {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [currentView, setCurrentView] = useState(VIEW_RECENT_ENCOUNTER);
  const [searchText, setSearchText] = useState(undefined);
  const [pcrTestKits, setPcrTestKits] = useState([]);
  const [filteredKits, setFilteredKits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvBtnDisabled, setCsvBtnDisabled] = useState(false);


  const [pageStart, setPageStart] = useState(0);
  const [totalCount, setTotalCount] = useState();
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0);

  const [practices, setPracites] = useState<IlistPractice["items"]>({});
  const [queryParams, setQueryParams] = useState<DefaultSearchParams>(defaultParams);
  const [showModal, setShowModal] = useState(false);
  const [shownColumns, setShownColumns] = useState(defaultColumns);
  const [sortType, setSortType] = useState<{ order: string; field: string }>({
    order: "descending",
    field: "timestamp",
  });

  const location = useLocation();
  const history = useHistory();

  // turns url paramerters into queryParam object
  const paramsToObjet = (entries) => {
    let params = {};
    for (const [key, value] of entries) {
      params[key] = value;
    }
    return params;
  };

  const monitorLocation = () => {
    let regex = new RegExp('/?');
    let regex2 = new RegExp('/encounters-');
    let theURL = window.location.href;
    let test = !regex.test(theURL) && regex2.test(theURL);
    return test
  }

  // onload handles setting queryParams to url params if user pastes url into browser
  useEffect(() => {
    // gets query string from url
    let urlParams = location.search;
    // turns query string into object
    const qParams = paramsToObjet(new URLSearchParams(urlParams));
    //checks length
    let lengthOfParams = Object.keys(qParams).length;
    if (lengthOfParams > 1) {
      setQueryParams(qParams);
    }
    else {
      setQueryParams(defaultParams);
    }
  }, []);
  

  useEffect(() => {
    setSortType({ order: "desc", field: "timestamp" });
    if(currentUser){
      if (currentView === VIEW_RECENT_ENCOUNTER) {
        fetchFilteredEncounters();
      } else {
        setLoading(true);
        api.UserRecords.getPcrTests()
          .then((res) => {
            setPcrTestKits(res.items);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }

    }
    return () => {
      onUnload();
    };
  }, [currentUser, currentView, queryParams, pageStart, shownColumns]);

  useEffect(() => {
    filterData("");
  }, [pcrTestKits]);

  useEffect(() => {
    const columns = window.localStorage.getItem("monitoringColumns");
    if (columns) {
      let parsed = JSON.parse(columns);
      setShownColumns(parsed);
    }
  }, []);

  useEffect(() => {
    const items = data ? data.items : []; 

    setItems(items);
    setFilteredItems(items);
    if (data) {
      setTotalCount(data?.totalCount);
      setPageSize(data?.pageSize);

      let pstart = queryParams?.pageStart ? queryParams.pageStart : 0;
      let pNum = queryParams?.page ? queryParams.page : 0;
      let sortBy = queryParams?.sortBy ? queryParams.sortBy : "timestamp";
      let order = queryParams?.sortOrder ? queryParams.sortOrder : "descending";
      setPage(pNum);
      setPageStart(pstart);
      setSortType({ order: order, field: sortBy });
    }
  }, [data]);

  const fetchFilteredEncounters = async () => {

    //Read url Params and use for api call
    let urlQuery = location.search;
    let qParams = urlQuery ? paramsToObjet(new URLSearchParams(urlQuery)) : defaultParams;

    onLoad(api.Monitoring.filteredEncounters({...qParams, pageStart: pageStart, pageSize: pageSize, page: page, sortOrder: sortType.order, sortBy: sortType.field}), urlQuery);
    setLoading(false);
  };


  const rowClick = (id: string, practiceID?: string) => (e: MouseEvent) => {
    e.stopPropagation();

    history.push({
      pathname: "/encounter-monitor/",
      search: window.location.search
    })
    viewEncounter(id)  
  };

  const changeView = (view) => {
    setCurrentView(view);
  };

  const filterData = (filterStr) => {
    const filteredKits = pcrTestKits.filter(
      (kit) =>
        kit.name.toLowerCase().indexOf(filterStr.toLowerCase()) > -1 ||
        kit.barcode.toLowerCase().indexOf(filterStr.toLowerCase()) > -1,
    );
    setFilteredKits(filteredKits);
  };



  const SearchEncounters = (params) => {
      let cleanedParams = Object.keys(params)
      .filter((k) => params[k] != null && params[k] != "")
      .reduce((a, k) => ({ ...a, [k]: params[k] }), {});
      
      let lengthCheck = Object.keys(cleanedParams).length > 1;
      if (lengthCheck) {
        history.push({
          pathname: "/encounter-monitor/",
          search: "?" + new URLSearchParams(cleanedParams).toString(),
        })
      }

    setPageStart(0);
    setQueryParams(params);
  };

  const ClearSearch = () => {

    history.push({
      pathname: "/encounter-monitor/",
      search: "",
    })

    setQueryParams(defaultParams);
  };

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'barcode' does not exist on type 'never'.
  const handleSearchTextChange = useCallback((text) => {
    setSearchText(text);
    filterData(text);
  });

  // page change handler
  const handleLoadNewItems = (pageStart, pageSize, page) => {
    // to offset the page from indexing at 0 for the api/url
    let pageView = page + 1;
   
      let paramsWithPage = {...queryParams, pageStart: pageStart, pageSize: pageSize, page: pageView};
      let cleanedParams = Object.keys(paramsWithPage)
      .filter((k) => paramsWithPage[k] != null && paramsWithPage[k] != "")
      .reduce((a, k) => ({ ...a, [k]: paramsWithPage[k] }), {});
      
      let lengthCheck = Object.keys(cleanedParams).length > 1;
      if (lengthCheck) {
        history.push({
          pathname: "/encounter-monitor/",
          search: "?" + new URLSearchParams(cleanedParams).toString(),
        })
      }
 
    setFilteredItems([]);
    setPageStart(pageStart);
    setPageSize(pageSize);
    setQueryParams({ ...queryParams, pageStart: pageStart, pageSize: pageSize, page: pageView });
  };

  const handleTableUpdate = (newList) => {
    setShownColumns(newList);
    localStorage.setItem("monitoringColumns", JSON.stringify(newList));
    setShowModal(false);
  };

  const handleSort = (field: string, order: string) => {
    if (sortType?.field === field && sortType?.order === order) {
      return;
    }
    setSortType({ order: order, field: field });
    setQueryParams({ ...queryParams, sortOrder: order, sortBy: field });
  };

  const csvGetter = () => {
    setCsvBtnDisabled(true)
    api.Monitoring.encountersCSV(queryParams)
      .then((response) => {
        window.location = response.DownloadLink
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() =>setCsvBtnDisabled(false))
  };

  return (
    <div>
      <NavBar />
      <CustomizeTableModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedColumns={shownColumns}
        setColumns={handleTableUpdate}
      />
      <div className="h-100">
        <div className="dashboard-component align-content-stretch encounter-monitor-container">
          <div className="d-flex justify-content-between w-100 mb-3">
            <span className="d-flex  ">
              <h1
                className={
                  currentView === VIEW_RECENT_ENCOUNTER
                    ? `selected qa-monitorEncounterMonitorHeaderToggle recent-encounter-tab`
                    : "qa-monitorEncounterMonitorHeaderToggle recent-encounter-tab"
                }
                onClick={() => changeView(VIEW_RECENT_ENCOUNTER)}
              >
                All Encounters
              </h1>
              <h1
                className={
                  currentView === VIEW_AT_HOME_PCR_TEST_KITS
                    ? `selected qa-monitorPCRTestKitHeaderToggle recent-encounter-tab`
                    : "qa-monitorPCRTestKitHeaderToggle recent-encounter-tab"
                }
                onClick={() => changeView(VIEW_AT_HOME_PCR_TEST_KITS)}
              >
                At-Home PCR Test Kits
              </h1>
            </span>
            {currentUser?.isBetaTester && (
              <Button color="primary" className="exportCsv" style={{borderRadius:"6px"}} disabled={csvBtnDisabled} onClick={csvGetter}>
                {csvBtnDisabled ? "Exporting..." : "Export to .csv"}
              </Button>
            )}
          </div>
          {currentView === VIEW_RECENT_ENCOUNTER && (
            <div className="search-container">
              <MonitorEncounterFilter
                getEncounterSearchResults={SearchEncounters}
                clearEncounterSearchResults={ClearSearch}
                queryParams={queryParams}
                setShowModal={setShowModal}
                showModal={showModal}
                totalCount={totalCount}
                pageSize={pageSize}
                pageStart={pageStart}
                onChange={handleLoadNewItems}
                permissions={props.permissions}
                page={page}
                setPage={setPage}
                paramsToObjet={paramsToObjet}
              />
            </div>
          )}

          {currentView === VIEW_RECENT_ENCOUNTER && (
            <>
              <div
                style={{
                  maxWidth: "100%",
                  overflowX: "scroll",
                }}
              >
                <Table className="encounter-monitor-table mt-0 table-container" striped hover>
                  <thead>
                    <tr>
                      {shownColumns.includes("timestamp") && (
                        <th className="encounter-monitor-table-dates">
                          <Sorter
                            field={"timestamp"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Visit Created"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("patientName") && (
                        <th>
                          <Sorter
                            field={"patientName"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Patient"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("location") && (
                        <th>
                          <Sorter
                            field={"location"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Location"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("patientSex") && (
                        <th>
                          <Sorter
                            field={"patientSex"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Patient Sex"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("patientAge") && (
                        <th>
                          <Sorter
                            field={"patientAge"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Patient Age"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("patientLanguage") && (
                        <th>
                          <Sorter
                            field={"patientLanguage"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Patient Language"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("patientReliantID") && (
                        <th>
                          <Sorter
                            field={"patientReliantID"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Patient Reliant ID"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("patientPCCID") && (
                        <th>
                          <Sorter
                            field={"patientPCCID"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Patient PCCID"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("providerName") && (
                        <th>
                          <Sorter
                            field={"providerName"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Provider"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("extender") && (
                        <th>
                          <Sorter
                            field={"extender"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Extender"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("scribe") && (
                        <th>
                          <Sorter
                            field={"scribe"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Scribe"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("medicalPracticeName") && (
                        <th>
                          <Sorter
                            field={"medicalPracticeName"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Practice"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("chiefComplaint") && (
                        <th>
                          <Sorter
                            field={"chiefComplaint"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Chief Complaint"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("patientWaitedTime") && (
                        <th>
                          <Sorter
                            field={"patientWaitedTime"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Time in Waiting Room"}
                          />
                        </th>
                      )}

                      {shownColumns.includes("communicationMethod") && (
                        <th>
                          <Sorter
                            field={"communicationMethod"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Method"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("latestVisitStatus") && (
                        <th>
                          <Sorter
                            field={"latestVisitStatus"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Visit Timeline"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("status") && (
                        <th>
                          <Sorter
                            field={"status"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"EMR Status"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("newMessages") && (
                        <th>
                          <Sorter
                            field={"newMessages"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"New Messages"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("lastContact") && (
                        <th>
                          <Sorter
                            field={"lastContact"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Last Message"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("prescribedDate") && (
                        <th>
                          <Sorter
                            field={"prescribedDate"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Prescription Written"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("pharmacyName") && (
                        <th>
                          <Sorter
                            field={"pharmacyName"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Pharmacy Name"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("medicationPerscribed") && (
                        <th>
                          <Sorter
                            field={"medicationPerscribed"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Medication Perscribed"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("prescriptionRXCUI") && (
                        <th>
                          <Sorter
                            field={"prescriptionRXCUI"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Prescription RXCUI"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("viaFormulary") && (
                        <th>
                          <Sorter
                            field={"viaFormulary"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Via Formulary"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("scribeDate") && (
                        <th>
                          <Sorter
                            field={"scribeDate"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Scribe Handoff"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("signedDate") && (
                        <th>
                          <Sorter
                            field={"signedDate"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Provider Signed"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("patientNumOfAttempts") && (
                        <th>
                          <Sorter
                            field={"patientNumOfAttempts"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Patient Video Attempts"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("neverEnteredWaitingRoom") && (
                        <th>
                          <Sorter
                            field={"neverEnteredWaitingRoom"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Patient Entered Waiting Room"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("patientExitedWaitingRoomPrematured") && (
                        <th>
                          <Sorter
                            field={"patientExitedWaitingRoomPrematured"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Patient Left Waiting Room"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("providerVideoChatTime") && (
                        <th>
                          <Sorter
                            field={"providerVideoChatTime"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Video Time"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("totalActiveChartingTime") && (
                        <th>
                          <Sorter
                            field={"totalActiveChartingTime"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Total Active Charting Time"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("encounterId") && (
                        <th>
                          <Sorter
                            field={"encounterId"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"EMR Encounter ID"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("organizationType") && (
                        <th>
                          <Sorter
                            field={"organizationType"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Organization Type"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("organization") && (
                        <th>
                          <Sorter
                            field={"organization"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Organization"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("clinicalServicesLocation") && (
                        <th>
                          <Sorter
                            field={"clinicalServicesLocation"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Clinical Services Location"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("healthGorillaSessions") && (
                        <th>
                          <Sorter
                            field={"healthGorillaSessions"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Health Gorilla Sessions"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("followUp") && (
                        <th>
                          <Sorter
                            field={"followUp"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"FollowUp"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("visitType") && (
                        <th>
                          <Sorter
                            field={"visitType"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Visit Type"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("chargeAmountToPatient") && (
                        <th>
                          <Sorter
                            field={"chargeAmountToPatient"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Charge Amount To Patient"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("promoCodeUsed") && (
                        <th>
                          <Sorter
                            field={"promoCodeUsed"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Promo Code Used"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("patientCopay") && (
                        <th>
                          <Sorter
                            field={"patientCopay"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Patient Copay"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("billedInsurance") && (
                        <th>
                          <Sorter
                            field={"billedInsurance"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Billed Insurance"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("patientPrimaryPayer") && (
                        <th>
                          <Sorter
                            field={"patientPrimaryPayer"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Patient Primary Payer"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("visitBillingLocation") && (
                        <th>
                          <Sorter
                            field={"visitBillingLocation"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Visit Billing Location"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("CPTCode") && (
                        <th>
                          <Sorter
                            field={"CPTCode"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"CPT Code"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("ICD10") && (
                        <th>
                          <Sorter
                            field={"ICD10"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"ICD10"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("suggestedFollowUpDate") && (
                        <th>
                          <Sorter
                            field={"suggestedFollowUpDate"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Suggested FollowUp Date"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("effectiveDate") && (
                        <th>
                          <Sorter
                            field={"effectiveDate"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Effective Date"}
                          />
                        </th>
                      )}
                      {shownColumns.includes("outcome") && (
                        <th>
                          <Sorter
                            field={"outcome"}
                            handleSort={handleSort}
                            sortType={sortType}
                            label={"Outcome"}
                          />
                        </th>
                      )}
                    </tr>
                  </thead>
                  {filteredItems?.length > 0 && (
                    <tbody>
                      {filteredItems?.map((item, idx) => (
                        <EncounterRow
                          encounter={item}
                          key={`recentEncounters-${idx}`}
                          onClick={rowClick}
                          className="qa-monitorEncounterRow monitorEncounterRow"
                          currentView={currentView}
                          shownColumns={shownColumns}
                        />
                      ))}
                    </tbody>
                  )}
                </Table>
                <div className="pagination-allEnounters">
                  <PaginationAE
                    totalCount={totalCount}
                    pageSize={pageSize}
                    pageStart={pageStart}
                    onChange={handleLoadNewItems}
                    page={page}
                    setPage={setPage}
                  />
                </div>
              </div>
            </>
          )}
          {currentView === VIEW_AT_HOME_PCR_TEST_KITS && (
            <>
              <div className="filterBar">
                <FormGroup className="d-flex">
                  <Input
                    placeholder="Search Patient Name or Barcode Number"
                    value={searchText}
                    onChange={(event) => handleSearchTextChange(event.target.value)}
                    className="qa-monitorPCRTestSearch"
                  />
                </FormGroup>
              </div>
              <Table className="encounter-monitor-pcr-testkits-table" striped hover>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Patient Name</th>
                    <th>Test Barcode Number</th>
                    <th className="encounter-status-cell qa-monitorPCRTestKitStatus">Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                    filteredKits?.map((kit, idx) => (
                      <TestKitRow
                        encounter={kit}
                        key={`qa-monitorTestKitRow-${idx}`}
                        className="qa-monitorTestKitRow"
                        currentUser={currentUser}
                      />
                    ))}
                </tbody>
              </Table>
            </>
          )}
          {(inProgress || loading) && (
            <div className="d-flex flex-column w-100 align-items-center">
              <Spinner />
            </div>
          )}
          {!inProgress &&
            !loading &&
            filteredItems?.length === 0 &&
            currentView === VIEW_RECENT_ENCOUNTER && (
              <div className="no-results-container">
                <div className="no-results-text">No results fit these search criteria.</div>
                <div className="no-results-sub-text">
                  Please try removing some search terms or <br /> start a new search to see results
                  here.
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Monitor);

import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

import styles from "./ChartTabs.scss";
import SelectorBar from "../SelectorBar";
import PCCBadge from "../PCCBadge";
import MedicalProfile from "./MedicalProfile/MedicalProfile";
import MedicalProfilePCC from "./MedicalProfile/MedicalProfilePCC";
import VitalsPanelPCC from "./VitalsPanelPCC";
import InsuranceInfoPanel from "./InsuranceInfoPanel";
import Documents from "./Documents";
import api from "../../api";
import { IPatientProfile } from "../../constants/Types";

const TAB_SETS = {
  pcc: [
    {
      type: "documents",
      text: "Documents",
    },
    {
      type: "profile",
      text: "Medical Profile",
    },
    {
      type: "vitals",
      text: "Vitals & Monitoring",
    },
  ],
  "non-pcc": [
    {
      type: "documents",
      text: "Documents",
    },
    {
      type: "profile",
      text: "Medical Profile",
      editEnabled: true,
    },
    {
      type: "insurance",
      text: "Insurance Info",
    },
  ],
};

function isEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const ChartTabs = ({
  userID,
  currentUser,
  isPCCPatient,
  profile,
  onSaveTab,
  onShowCovidUploadForEntry,
  medicalCatalog,
  surgicalCatalog,
  setProfile,
  profilePCC,
  allergiesPCC,
  conditionsPCC,
  medicationsPCC,
  coveragePCC,
  immunizationPCC,
  restoreOldProfile,
  entriesLoaded,
  entries,
  setEntries,
  loadEntries,
  permissions,
  canEditNote,
  assessmentsPCC,
  handleImages,
  ...props
}) => {
  const tabSet = isPCCPatient ? "pcc" : "non-pcc";
  const options = TAB_SETS[tabSet] || [];
  const initialTab = (options[0] || {}).type || "";
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [isEditActive, setEditActive] = useState(false);
  const [oldProfile, setOldProfile] = useState<IPatientProfile>({} as IPatientProfile);
  useEffect(() => {
    setOldProfile(profile);
  }, []);

  const onOptionSelected = (type) => {
    if (!isEditActive) {
      setSelectedTab(type);
    } else {
      setSelectedTab(selectedTab);
      return false;
    }
  };

  const updateMedicalProfileDetails = (fieldName) => (value) => {
    setProfile({
      ...profile,
      medicalProfile: { ...profile.medicalProfile, [fieldName]: value },
    });
  };

  const onEditSave = () => {
    handleMedicalProfileSave();

    setEditActive(!isEditActive);
  };

  const onCancel = () => {
    restoreOldProfile(oldProfile);
    setEditActive(!isEditActive);
  };

  const handleMedicalProfileSave = () => {
    api.UserRecords.updateMedicalProfile(userID, profile.medicalProfile);
    setOldProfile(profile);
  };

  const barRenderer = () =>
    !!isPCCPatient ? (
      <PCCBadge />
    ) : (
      <div style={{ width: 180 }} className="d-flex justify-content-end">
        {!!(options.find((opt) => opt.type === selectedTab) || {}).editEnabled && (
          <>
            {!isEditActive ? (
              <Button
                className="edit-button qa-chartTabsEditButton"
                onClick={() => setEditActive(!isEditActive)}
              >
                Edit
              </Button>
            ) : (
              <>
                <div onClick={onCancel} className="cancel-btn qa-chartTabsCancelButton">
                  Cancel
                </div>
                <Button className="save-button qa-chartTabsSaveButton" onClick={onEditSave}>
                  Save
                </Button>
              </>
            )}
          </>
        )}
      </div>
    );

  const medicalProfile = (profile || {}).medicalProfile || {};
  const insuranceProfile = medicalProfile.insuranceProfile || {};
  const card = (profile || {}).insuranceCardURLs || {};

  return (
    <div className={styles.chartTabs}>
      <SelectorBar
        options={options}
        selected={selectedTab}
        leftBarRenderer={barRenderer}
        onOptionSelected={onOptionSelected}
      />
      <div className={`${styles.tabPanel} ${selectedTab === "profile" ? styles.hideBg : ""}`}>
        {selectedTab === "documents" ? (
          <div>
            <Documents
              permissions={permissions}
              userID={userID}
              profile={profile}
              isPccIntegrated={isPCCPatient}
              onShowCovidUploadForEntry={onShowCovidUploadForEntry}
              entries={entries}
              setEntries={setEntries}
              entriesLoaded={entriesLoaded}
              loadEntries={loadEntries}
              canEditNote={canEditNote}
              handleImages={handleImages}
            />
          </div>
        ) : selectedTab === "profile" && isEmpty(profilePCC) ? (
          <MedicalProfile
            profile={profile.medicalProfile}
            isEditing={isEditActive}
            restoreData={oldProfile.medicalProfile || {}}
            medicalCatalog={medicalCatalog}
            surgicalCatalog={surgicalCatalog}
            onChange={updateMedicalProfileDetails}
            userID={userID}
            patient={profile.patient}
          />
        ) : selectedTab === "profile" && !isEmpty(profilePCC) ? (
          <MedicalProfilePCC
            assessmentsPCC={assessmentsPCC}
            profile={profilePCC}
            allergies={allergiesPCC}
            medications={medicationsPCC}
            diagnosis={conditionsPCC}
            immunizationPCC={immunizationPCC}
            familyMedicalHistory={profile.medicalProfile.FamilyMedicalHistory}
          />
        ) : selectedTab === "insurance" ? (
          <div>
            <InsuranceInfoPanel userID={userID} insuranceProfile={insuranceProfile} card={card} />
          </div>
        ) : selectedTab === "vitals" ? (
          <div style={{ width: "100%" }}>
            <VitalsPanelPCC userID={userID} currentUser={currentUser} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

ChartTabs.propTypes = {
  userID: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  isPCCPatient: PropTypes.bool,
  profile: PropTypes.object,
  onSaveTab: PropTypes.func,
  onShowCovidUploadForEntry: PropTypes.func,
  medicalCatalog: PropTypes.arrayOf(PropTypes.object),
  surgicalCatalog: PropTypes.array,
  setProfile: PropTypes.func,
  profilePCC: PropTypes.object,
  allergiesPCC: PropTypes.array,
  conditionsPCC: PropTypes.array,
  medicationsPCC: PropTypes.array,
  coveragePCC: PropTypes.object,
  immunizationPCC: PropTypes.array,
  restoreOldProfile: PropTypes.func,
  entriesLoaded: PropTypes.bool,
  entries: PropTypes.array,
  setEntries: PropTypes.func,
  loadEntries: PropTypes.func,
};

export default ChartTabs;

import React, { useState } from "react";

import BlockSection from "./BlockSection";
import SymptomEditorWithDetails from "./SymptomEditorWithDetails";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import CheckToggle from "../CheckToggle";
import ContentBlock from "./ContentBlock";

const EmptyComponent = () => {
  return null;
};

const ROSEditor = (props) => {
  const systems = (props.ros || {}).systems;
  const d = props.details || { symptoms: [] };
  const ros = props.ros.systems || {};
  const symptomsDetailField = props.symptomsDetailField;
  const symptomByStystemField = props.symptomByStystemField;
  const [additionalRos, setAdditional] = useState<string>(props.additionalRos || "");
  const [checked, setChecked] = useState<boolean>(props.ROSGotSSC);
  const onAdditionalChange = (event) => {
    setAdditional(event.target.value);
    props.onChange({ reviewOfSystemsWritten: event.target.value });
    //props.onChange("reviewOfSystemsWritten", event.target.value);
  };
  const onCheckBoxChanged = (value) => {
    setChecked(value);
    props.onChange({ reviewOfSystemsGotSSC: value });
    // props.onChange("reviewOfSystemsGotSSC", value);
  };

  const onChange = (value) => {
    props.onChange(value);
    // props.onChange(field, value);
  };

  return (
    <div>
      <h1 className="block-title">ROS</h1>
      <CheckToggle
        size={"small"}
        className="qa-encounter-covidprotocol-checkbox"
        checked={checked}
        onChange={(value) => onCheckBoxChanged(value)}
      >
        <p>ROS was not obtainable due to patient condition</p>
      </CheckToggle>
      {props.itemOrder.map((order, index) => {
        let options = [];
        let systemItemorder = [];

        order.items.forEach((item) => {
          if (props.relevantSymptomsKeys.indexOf(item.key) < 0) {
            item.group = order.systemKey;
            if (!systems[order.systemKey] || systems[order.systemKey].indexOf(item.key) < 0) {
              options.push(item);
            } else {
              systemItemorder.push(item);
            }
          }
        });
        return (
          <BlockSection title={`ROS - ${order.systemName}`} key={index}>
            <div className="d-flex w-100">
              <div className="system-edit-container w-100">
                <div className="w-100">
                  <SymptomEditorWithDetails
                    order={systemItemorder}
                    system={systems[order.systemKey]}
                    details={d.symptoms}
                    onChange={onChange}
                    components={{
                      IndicatorSeparator: EmptyComponent,
                      MultiValueContainer: EmptyComponent,
                    }}
                    options={options}
                    ros={ros}
                    symptomsDetailField={symptomsDetailField}
                    symptomByStystemField={symptomByStystemField}
                    systems={systems}
                  />
                </div>
              </div>
            </div>
          </BlockSection>
        );
      })}
      <BlockSection childClassName="pr-3" title="Additional or written ROS">
        <Input
          value={additionalRos}
          onChange={onAdditionalChange}
          placeholder="Enter additional comments or self written ROS"
          type="textarea"
          name="providerNotes"
          className="line-breaks"
          id="qa-encounter-hpi-freetext"
        />
      </BlockSection>
    </div>
  );
};

ROSEditor.propTypes = {
  catalog: PropTypes.object,
  details: PropTypes.object,
  itemOrder: PropTypes.arrayOf(PropTypes.object),
  oldRos: PropTypes.object,
  onChange: PropTypes.func,
  relevantSymptomsKeys: PropTypes.array,
  ros: PropTypes.object,
  symptomByStystemField: PropTypes.string.isRequired,
  symptomsDetailField: PropTypes.string.isRequired,
  visitType: PropTypes.string.isRequired,
};
export default ROSEditor;

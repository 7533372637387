import React, { Component, Dispatch } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Input } from "reactstrap";

import api from "../../api";
import CenteredForm from "../CenteredForm";
import ErrorList from "../ErrorList";
import { REQUEST_PASSWORD_RESET, NAVIGATE_TO } from "../../constants/actionTypes";
import { IAppState } from "../../reducer";

const mapStateToProps = (state: IAppState) => {
  return {
    inProgress: state.progress[REQUEST_PASSWORD_RESET],
    currentUser: state.common.currentUser,
    errors: state.errors[REQUEST_PASSWORD_RESET],
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onSubmit: (email: string) =>
    dispatch({
      type: REQUEST_PASSWORD_RESET,
      payload: api.Authorization.requestPasswordReset(email),
      targetPath: "/login",
      flashMessage:
        "Password reset request sent, please check your email for instructions on how to reset your password",
    }),
  onLoggedIn: (destination: string) =>
    dispatch({
      type: NAVIGATE_TO,
      targetPath: destination,
      flashMessage: "Cannot reset password, you are already logged in.",
    }),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type State = {
  email: string;
  [key: string]: string;
};

class ForgotPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.currentUser) {
      this.props.onLoggedIn("/");
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.props.onSubmit(this.state.email);
  }

  render() {
    return (
      <CenteredForm>
        <h4>Request Password Reset</h4>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Input
              type="email"
              name="email"
              placeholder="your.email@address.com"
              value={this.state.email}
              disabled={this.props.inProgress}
              onChange={this.handleChange}
              id="email"
            />
          </FormGroup>
          <ErrorList errors={this.props.errors} />
          <Button disabled={this.props.inProgress} color="primary">
            Request Reset
          </Button>
        </Form>
        <div className="reset-container">
          <Link className="reset-link" to="/login">
            Cancel
          </Link>
        </div>
      </CenteredForm>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPassword);

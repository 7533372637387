import superagentPromise from "superagent-promise";
import _superagent, { Response, Plugin } from "superagent";
import queryString from "query-string";
// API types
import {
  IAdvanceDirectivesConsentList,
  IAllergiesInfo,
  IConditions,
  ICoverage,
  IMedicationList,
  INotConsentedUsersResult,
  IObservationList,
  IPractitioners,
  IProgressNote,
  IProgressNoteList,
  I_Immunizations,
  IPccPatientProfile,
  IpcrTests,
  IUserCreationOptions,
  ICreateUser,
  I_InsuranceVerifications,
  ICovidHistory,
  I_InsuranceHistory,
  I_IndexedUserPage,
  IChartItems,
  IEditChartItemRequest,
  IChartEntry,
  IAddChartFileRequest,
  IUserChartEntry,
  IMedicalProfile,
  IUpdateUserProfileRequest,
  IEncounterSearchOptions,
  IEncounterSearchResult,
  IVisitHistory,
  IPusherProviderInfo,
  IRoundingItem,
  IAssignableProviderItem,
  IlistPractice,
  IProviderParameters,
  IProviderList,
  IDosespotNotifications,
  IMonitorEncounter,
  IEncounterList,
  IActivityMonitoring,
  IVersionCheck,
  IMedicalPracticeList,
  ISerializedItemKey,
  ICompleteFaxResponse,
  IAssignFaxResponse,
  IFaxListResponse,
  IEncounterSaveRespone,
  IAllProviderSavedEncounters,
  ITestResponse,
  IVisitHistoryResponse,
  ICheckPrescriptionsResponse,
  IProviderTestResult,
  IUserMedicalIntake,
  IProviderNote,
  IPatientProfile,
  IStartEncounterResponse,
  IEncounterDetailsResponse,
  IEngagementStatusResponse,
  IEngageEncounterResponse,
  IEncounterListResponse,
  IConditionSpecificIntake,
  ISkinPrickTests,
  IKeyedLabelEntry,
  IReviewOfSystems,
  IotcMedication,
  ISymptomSupportiveCare,
  IICD10TxPlan,
  ICatalogEndpointResponse,
  IPresenceResponse,
  IAuditStatisticsResponse,
  IAuditEncountersQuery,
  IEncounterSummaryList,
  ICurrentUser,
  ILoginResponse,
  ISupervisingPhysicianResponse,
  IAuditProvidersQuery,
  IAuditProviderListResponse,
  IMedicalDirectorResponse,
  IAssessmentList,
  IChatHistory,
  ICPTCodesResponse,
  IRelationshipResponse,
  IFamilyHealthConditionsResponse,
  IEncounterImages,
  IEncounterTimelines,
  IUpdateLog,
  IEncounterSMSTimelines,
  IMointorEncounterEncounterMethod,
  IPresenceTimeline,
  encounterCSV,
  IChatTemplatesMessages,
  ITemplate, 
  ITemplateBody,
  IEMRNotifications,

} from "./constants/Types";

const superagent = superagentPromise(_superagent, global.Promise);

let token = null;
const responseBody = (res: Response) => res.body;
const responseBlob = (res: Response) => res.text;
const tokenPlugin: Plugin = (req) => {
  if (token) {
    req.set("Authorization", `bearer ${token}`);
  }
  req.set("Content-Type", "application/json");
  req.set("x-api-key", API_KEY);
};
const csvPlugin = (req) => {
  if (token) {
    req.set("Authorization", `bearer ${token}`);
  }
  req.set("Content-Type", "text/csv");
  req.set("x-api-key", API_KEY);
};

const requests = {
  del: <responseBody = any>(url: string, body: object) =>
    superagent
      .del(`${API_ROOT}${url}`, JSON.stringify(body))
      .send(body)
      .use(tokenPlugin)
      .then<responseBody>(responseBody),
  get: <responseBody = any>(url: string) =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then<responseBody>(responseBody),
  getCSV: (url: string, query: object) =>
    superagent
      .get(`${API_ROOT}${url}?${queryString.stringify(query)}`)
      .use(tokenPlugin)
      .then(responseBlob),
  getWithQuery: <responseBody = any>(url: string, query: object) =>
    superagent
      .get(`${API_ROOT}${url}?${queryString.stringify(query)}`)
      .use(tokenPlugin)
      .then<responseBody>(responseBody),
  query: <responseBody = any>(url: string, query?: object) =>
    superagent
      .get(`${API_ROOT}${url}?${queryString.stringify({ query })}`)
      .use(tokenPlugin)
      .then<responseBody>(responseBody),
  put: <responseBody = any>(url: string, body: object) =>
    superagent
      .put(`${API_ROOT}${url}`, JSON.stringify(body))
      .use(tokenPlugin)
      .then<responseBody>(responseBody),
  post: <responseBody = any>(url: string, body: object) =>
    superagent
      .post(`${API_ROOT}${url}`, JSON.stringify(body))
      .use(tokenPlugin)
      .then<responseBody>(responseBody),
  postWithEncounterID: <responseBody = any>(url: string, body: object, encounterID: string) =>
    superagent
      .post(`${API_ROOT}${url}`, JSON.stringify(body))
      .set("encounterID", encounterID)
      .use(tokenPlugin)
      .then<responseBody>(responseBody),
  getWithEncounterID: <responseBody = any>(url: string, encounterID: string) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .set("encounterID", encounterID)
      .use(tokenPlugin)
      .then<responseBody>(responseBody),
  putBinary: (url: string, content: any, headers: object) =>
    superagent
      .put(url)
      .set(headers)
      .set("Content-Disposition", "inline")
      .set("Content-Type", "image/png")
      .send(content),
  putImage: <responseBody = any>(url: string, body: object, headers: object) =>
    superagent
      .put(url, body)
      .set(headers)
      .set("Content-Type", "application/pdf")
      .then<responseBody>(responseBody),
  paginatedGetWithQuery: async function* <responseBody extends { nextPageToken?: string }>(
    url: string,
    query: object,
  ) {
    let response: responseBody | undefined;
    do {
      response = await this.getWithQuery(url, {
        ...query,
        pageToken: (response && response.nextPageToken) || undefined,
      });
      yield response;
    } while (response && response.nextPageToken);
  },
};

const Authorization = {
  login: (email: string, password: string) =>
    requests.post<ILoginResponse>("/providers/login", {
      email: email,
      password: password,
    }),
  current: () => requests.get<ICurrentUser>("/providers/current-user"),
  resetPassword: (resetID: string, password: string, passwordConfirmation: string) =>
    requests.post<{}>("/providers/reset-password", {
      resetID: resetID,
      password: password,
      passwordConfirmation: passwordConfirmation,
    }),
  requestPasswordReset: (email: string) =>
    requests.post<{}>("/providers/request-password-reset", { email }),
  refreshToken: () =>
    requests.post<{ token?: string; currentPracticeID: string; currentEMRHash: string }>(
      "/providers/refresh-token",
      {},
    ),
  switchPractice: (practiceID: string) =>
    requests.post<{ token?: string; currentPracticeID: string }>("/providers/switch-practice", {
      practiceID,
    }),
};

const Auditing = {
  encounters: (query: IAuditEncountersQuery = {}) =>
    requests.paginatedGetWithQuery<IEncounterSummaryList>("/auditing/encounters", query),
  auditStatistics: (providerID: string) =>
    requests.getWithQuery<IAuditStatisticsResponse>("/auditing/provider-audit-statistics", {
      providerID,
    }),
  audit: (key: string, auditNotes: string | null) =>
    requests.post<{}>("/auditing/audit", { key, auditNotes }),
  providers: (query: IAuditProvidersQuery = {}) =>
    requests.getWithQuery<IAuditProviderListResponse>("/auditing/providers", query),
};
const Availability = {
  presence: () => requests.get<Omit<IPresenceResponse, "endTime">>("/providers/presence"),
  clockIn: () => requests.post<IPresenceResponse>("/providers/clock-in", {}),
  clockOut: () => requests.post<{}>("/providers/clock-out", {}),
};

const Catalog = {
  allergies: (query: object) => requests.query<{ items: string[] }>("/catalog/allergies", query),
  medicalHistory: (query?: object) =>
    requests.query<IKeyedLabelEntry[]>("/catalog/past-medical-history-keys", query), // backend doesn't parse query string
  surgicalHistory: (query?: object) =>
    requests.query<IKeyedLabelEntry[]>("/catalog/past-surgical-history-keys", query), // backend doesn't parse query string
  icd10: (query: object) => requests.query<ICatalogEndpointResponse>("/catalog/icd-10", query),
  cptCodes: () => requests.get<ICPTCodesResponse>("/cpt-codes"),
  icd10TX: (query: { icd10: string }) =>
    requests.getWithQuery<IICD10TxPlan>("/catalog/icd-10-tx", query),
  symptomSupportiveCare: (query: { visitType: string }) =>
    requests.getWithQuery<ISymptomSupportiveCare>("/catalog/symptom-supportive-care", query),
  otcMedications: () => requests.get<IotcMedication>("/catalog/otc-medications"),
  reviewOfSystems: (query?: object) =>
    requests.query<IReviewOfSystems>("/catalog/review-of-systems", query), // backend doesn't parse query string
  medicationsTried: (query: { condition: string }) =>
    requests.getWithQuery<IKeyedLabelEntry[]>("/catalog/medications-tried", query),
  allergySkinTests: (query: object) =>
    requests.query<ISkinPrickTests[]>("/catalog/skin-prick-tests", query), // backend doesn't parse query string
  conditionSpecificIntake: (query: object) =>
    requests.query<IConditionSpecificIntake>("/catalog/condition-specific-intake", query), // backend doesn't parse query string,
  relationshipToPatient: (query?: object) =>
    requests.query<IRelationshipResponse>("/catalog/relationship-to-patient", query),
  familyHealthConditions: (query?: object) =>
    requests.query<IFamilyHealthConditionsResponse>("/catalog/family-health-conditions", query),
};

const Encounters = {
  pending: () => requests.get<IEncounterListResponse>("/provider-encounters/pending"),
  available: () => requests.get<IEncounterListResponse>("/provider-encounters/available"),
  tytocareLink: (encounterID: string) =>
    requests.getWithEncounterID<{ reviewURL: string }>(
      "/provider-encounters/tytocare-link",
      encounterID,
    ),
  engage: (referenceID: string) =>
    requests.post<IEngageEncounterResponse>("/provider-encounters/engage", { referenceID }),
  intiate: (userID: string, visitType: string) =>
    requests.post<IEngagementStatusResponse>("/provider-encounters/initiate-no-patient-encounter", {
      userID,
      visitType,
    }),
  deleteEncounter: (payload: ISerializedItemKey | {}) =>
    requests.post<{}>(`/provider-encounters/archive`, payload),
  engagementStatus: () =>
    requests.get<IEngagementStatusResponse>("/provider-encounters/engagement-status"),
  details: (encounterID: string) =>
    requests.getWithEncounterID<IEncounterDetailsResponse>(
      "/provider-encounters/details",
      encounterID,
    ),
  finish: (encounterID: string) =>
    requests.postWithEncounterID<{}>("/provider-encounters/finish", {}, encounterID),
  start: (encounterID: string) =>
    requests.postWithEncounterID<IStartEncounterResponse>(
      "/provider-encounters/start",
      {},
      encounterID,
    ),
  saveRoomEvent: (encounterID: string, eventBody: { key: string; description: string }) =>
    requests.postWithEncounterID<{}>(
      "/provider-encounters/save-video-event",
      eventBody,
      encounterID,
    ),
  videoComplete: (encounterID: string) =>
    requests.postWithEncounterID<{}>("/provider-encounters/video-complete", {}, encounterID),
  note: (encounterID: string) =>
    requests.getWithEncounterID<IProviderNote>("/provider-encounters/note", encounterID),
  saveNote: (encounterID: string, note: Partial<IProviderNote>) =>
    requests.postWithEncounterID<{}>("/provider-encounters/note", note, encounterID),
  saveProfile: (encounterID: string, profile: Partial<IMedicalProfile>) =>
    requests.postWithEncounterID<{}>("/provider-encounters/medical-profile", profile, encounterID),
  saveIntake: (encounterID: string, intake: Partial<IUserMedicalIntake>) =>
    requests.postWithEncounterID<{}>("/provider-encounters/medical-intake", intake, encounterID),
  saveProviderTestResult: (encounterID: string, result: IProviderTestResult) =>
    requests.postWithEncounterID<{}>(
      "/provider-encounters/provider-test-result",
      result,
      encounterID,
    ),
  prescriptionLink: (encounterID: string) =>
    requests.getWithEncounterID<{ link: string }>(
      "/provider-encounters/prescription-link",
      encounterID,
    ),
  checkPrescriptions: (encounterID: string) =>
    requests.getWithEncounterID<ICheckPrescriptionsResponse>(
      "/provider-encounters/check-prescriptions",
      encounterID,
    ),
  encounterHistoryChat: (encounterID: string) =>
    requests.get<IChatHistory>(`/provider-encounters/get-async-chat-messages/${encounterID}`),
  sendMessage: (message: { message: string }, encounterID: string) =>
    requests.postWithEncounterID<{}>(
      `/provider-encounters/send-message-to-async-chat`,
      message,
      encounterID,
    ),
  askForImage: (encounterID: string) =>
    requests.postWithEncounterID<{}>(`/provider-encounters/ask-for-image`, {}, encounterID),
  notePDF: (encounterID: string) =>
    requests.getWithEncounterID<{ url: string }>("/provider-encounters/note-pdf", encounterID),
  visitHistory: (encounterID: string) =>
    requests.getWithEncounterID<IVisitHistoryResponse>(
      "/provider-encounters/visit-history",
      encounterID,
    ),
  abandon: (encounterID: string, reason: string) =>
    requests.postWithEncounterID<{}>("/provider-encounters/abandon", { reason }, encounterID),
  assign: (referenceID: string, providerID: string) =>
    requests.post<{}>("/encounter-assignment/assign", { referenceID, providerID }),
  pcrTestResults: (encounterID: string) =>
    requests.getWithEncounterID<ITestResponse>("/provider-encounters/test-results", encounterID),
  unsignedEncounters: () =>
    requests.get<IAllProviderSavedEncounters>("/provider-encounters/unsigned-encounters"),
  saveAndExit: (encounterID: string) =>
    requests.postWithEncounterID<IEncounterSaveRespone>(
      "/provider-encounters/save",
      {},
      encounterID,
    ),
  resumeSavedEncounter: (encounterKey: ISerializedItemKey) =>
    requests.post<{}>(`/provider-encounters/resume`, encounterKey),
  vitalsUpdate: (payload: { vitals: string }, encounterID: string) =>
    requests.postWithEncounterID<{}>(`/provider-encounters/freeform-vitals`, payload, encounterID),
  updateChiefComplaint: (payload: { chiefComplaint: string }, encounterID: string) =>
    requests.postWithEncounterID<{}>(`/provider-encounters/chief-complaint`, payload, encounterID),
  updateTimeSpent: (payload: { timeSpent: number }, encounterID: string) =>
    requests.postWithEncounterID<{}>(`/provider-encounters/time-spent`, payload, encounterID),
  updateDateRange: (payload: { start: string; end: string }, encounterID: string) =>
    requests.postWithEncounterID<{}>(`/provider-encounters/date-reviewed`, payload, encounterID),
  syncDosespotPrescription: (userID: string) =>
    requests.post<{}>(`/provider-encounters/sync-dosespot-prescription`, { userID }),
  updateEffectiveDate: (payload: { date: string }, encounterID: string) =>
    requests.postWithEncounterID<{}>(`/provider-encounters/effective-date`, payload, encounterID),
  setRequisitionStatus: (
    status: { status: string[]; prescribeOutsideFormulary?: Boolean },
    encounterID: string,
  ) =>
    requests.postWithEncounterID<{}>(`/provider-encounters/additional-status`, status, encounterID),
  getBlankLTCMapping: () =>
    requests.get<{ links: { [key: string]: string } }>(`/provider-encounters/blank-ltc-mapping`),
  getBlankLTCTemplates: (visitName: string) =>
    requests.get<{ visitName: string; template: string }>(
      `/provider-encounters/blank-ltc-template-item/${visitName}`,
    ),
  setDownloadPdfUrl: (referenceID: string) =>
    requests.get("/pdfgenerator/generate-pdf/" + referenceID),
  getFormulary: (pharmacyID: string) => requests.get("/formularies/pharmacy/" + pharmacyID),
  getAllFormularies: () => requests.get("/formularies/all"),
  assignProvider: (payload: { providerID: string }, encounterID) =>
    requests.postWithEncounterID(
      "/provider-encounters/scribe-encounter-transfer",
      payload,
      encounterID,
    ),
  encounterImages: (encounterID: string) =>
    requests.get<IEncounterImages>(`/provider-encounters/get-encounter-images/${encounterID}`),
  archiveEncounterImage: (encounterID: string, rawImagePath: string) =>
    requests.post<{}>(`/provider-encounters/archive-encounter-image`, {
      archive: true,
      encounterRefernceID: encounterID,
      rawImagePath: rawImagePath,
    }),
  abortEncounter: (referenceID) =>
    requests.post<{ encounterID: string; status: string }>(`/provider-encounters/abort`, {
      referenceID,
    }),
  getVisitOutcome: (encounterReferenceKey) =>
    requests.get<{ visitOutcome: string }>(
      `/provider-encounters/visit-outcome/${encounterReferenceKey}`,
    ),
  postVisitOutcome: (encounterReferenceKey, visitOutcome) =>
    requests.post<{}>(`/provider-encounters/visit-outcome/${encounterReferenceKey}`, {
      visitOutcome,
    }),
  getChatTemplates: (encounterID: string) =>
    requests.getWithEncounterID<IChatTemplatesMessages>(`/provider-encounters/get-chat-message-templates`, encounterID),
  updateChatTemplates: (body: ITemplateBody, templateName: string) =>
    requests.put<IChatTemplatesMessages>(`/providers/update-chat-message-template`, { ...body,templateName }),
  addChatTemplates: (body: ITemplateBody) =>
    requests.post<IChatTemplatesMessages>(`/providers/create-chat-message-template`, body),
  deleteChatTemplates: (templateName: string) =>
    requests.del<IChatTemplatesMessages>(`/providers/remove-chat-message-template`,{ templateName }),

};
const HealthGorilla = {
  viewHealthGorillaChart: (userID: string) =>
    requests.get<{ url: string }>(`/healthgorilla/sso-link/${userID}`),
};
const Faxes = {
  unassignedFaxes: () => requests.get<IFaxListResponse>("/faxes/unassigned-faxes"),
  assignedFaxes: () => requests.get<IFaxListResponse>("/faxes/assigned-faxes"),
  assignFax: (faxID: ISerializedItemKey, providerID: string) =>
    requests.post<IAssignFaxResponse>("/faxes/assign-fax", { faxID, providerID }),
  completeFax: (faxID: ISerializedItemKey) =>
    requests.post<ICompleteFaxResponse>("/faxes/complete-fax", { faxID }),
};
const Invitations = {
  accept: (inviteID: string, password: string, passwordConfirmation: string) =>
    requests.post<{}>("/providers/accept-invitation", {
      inviteID: inviteID,
      password: password,
      passwordConfirmation: passwordConfirmation,
    }),
};
const ImageUpload = {
  prepare: (extension: string) =>
    requests.get(`/image-upload/provider-prepare?${queryString.stringify({ extension })}`),
  upload: (url: string, body: object, headers: object) => requests.putImage(url, body, headers),
};
const MedicalPractices = {
  list: () => requests.get<IMedicalPracticeList>("/medical-practices"),
};
const MedicalProfile = {
  medicationsSearch: (query: string, userID: string) =>
    requests.getWithQuery<{ item: string[] }>("/medical-profile/providers/medication-search", {
      query,
      userID,
    }),
};
const MobileVersions = {
  check: () => requests.get<IVersionCheck>("/mobile-versions/check"),
};
const Monitoring = {
  activitySummary: (payload: { practiceID: string }) =>
    requests.post<IActivityMonitoring>("/monitoring/activity-summary", payload),
  filteredEncounters: (query: object) =>
    requests.getWithQuery<IEncounterList>(`/monitoring/encounters`, query),
  encounterDetails: (encounterID: string) =>
    requests.get<IMonitorEncounter>(`/monitoring/encounters/${encounterID}`),
  postponedEncounters: (pageToken?: string) =>
    requests.get(`/monitoring/postponed-encounters?nextPageToken=${pageToken}`),
  encounterTimelines: (referenceID: string) =>
    requests.get<IEncounterTimelines>(`/provider-encounters/timeline/${referenceID}`),
  encounterSMSTimelines: (referenceID: string, size) =>
    requests.getWithQuery<IEncounterSMSTimelines>(`/provider-encounters/sms-timeline/${referenceID}`, {size: size}),
  encounterCharts: (query: object) =>
  requests.getWithQuery(`/monitoring/encounters-charts`, query),
  recentVisitsCount: (query: object) => 
    requests.getWithQuery<IMointorEncounterEncounterMethod>(`/monitoring/encounters-charts-method`, query),
  encountersCSV: (query: object) => 
    requests.getWithQuery<encounterCSV>(`/monitoring/csv-encounters`, query),

};

const NoteAddendum = {
  create: (key: string, content: string) => requests.post<{}>("/note-addendum", { key, content }),
};
const Notifications = {
  get: () => requests.get<IDosespotNotifications>("/providers/dosespot-notifications"),
  refillLink: () => requests.get<{ link: string }>("/providers/refill-link"),
};
const Prescriptions = {
  dosespotLink: (userID: string) =>
    requests.getWithQuery<{ link: string }>("/prescriptions/dosespot-link", { userID }),
};

const Providers = {
  get: (id: string) => requests.get<IProviderParameters>(`/providers/${id}`),
  update: (data: IProviderParameters) => requests.post<{}>("/providers/update", data),
  list: (query: { nextPageToken?: string; scope?: string }) =>
    requests.getWithQuery<IProviderList>("/providers", {
      withAuditStatistics: true,
      ...query,
    }),
  listnoAudit: (query: { nextPageToken?: string; scope?: string }) =>
    requests.getWithQuery<IProviderList>("/providers", {
      withAuditStatistics: false,
      ...query,
    }),
  setActive: (providerID: string, active: boolean) =>
    requests.post<{ active: string; providerID?: string }>("/providers/set-active-state", {
      providerID,
      active,
    }),
  invite: (data: IProviderParameters) => requests.post<{}>("/providers/invite", data),
  resendInvite: (data: { email: string }) => requests.post<{}>("/providers/resend-invite", data),
  medicalDirectors: (state: string) =>
    requests.getWithQuery<IMedicalDirectorResponse>("/providers/medical-directors", { state }),
  supervisingPhysician: (state?: string) =>
    requests.getWithQuery<ISupervisingPhysicianResponse>("/providers/supervising-physicians", {
      state,
    }),
  setVideoSetup: (setup: boolean) =>
    requests.post<{}>("/providers/set-video-setup", { videoSetup: setup }),
  listPractices: () => requests.get<IlistPractice>("/providers/practices"),
  getAssignable: (encounterID: string | null) =>
    requests.get<{ items: IAssignableProviderItem[] }>(`/providers/assignable-list/${encounterID}`),
  getRoundingList: () =>
    requests.get<{ items: IRoundingItem[] }>(`/providers/rounding-patient-list`),
  changeCovidZone: (userID: string, payload: { covidZone: string }) =>
    requests.put<{}>(`/providers/rounding-patient-list/${userID}/covidZone`, payload),
  changeRoundingFlag: (userID: string, payload: { activeRounding: boolean }) =>
    requests.put<{}>(`/providers/rounding-patient-list/${userID}/ActiveRounding`, payload),
  changeConsent: (payload: {
    consentStatus: boolean;
    consentReason: string;
    userID: string;
    ccm?: boolean;
    rpm?: boolean;
  }) => requests.put<{}>(`/providers/set-consented-status`, payload),
  getNotConsentedUsers: (practiceID?: string, nextPageToken?: string) =>
    requests.getWithQuery<INotConsentedUsersResult>(`/providers/not-consented-users`, {
      pageToken: nextPageToken,
      practiceID,
    }),

  getUpdateLog: (providerID: string, size: number) =>
    requests.getWithQuery<IUpdateLog>(`/providers/update-log/${providerID}`, {size: size} ),
  providerFilter: (query: object) =>
    requests.getWithQuery(`/providers/search`, query),
  getPresenceTimeline: (providerID: string,nextDate?:string) =>
    requests.getWithQuery<IPresenceTimeline>(`/providers/presence-timeline/${providerID}`,{
      start: nextDate
    }),

};
const Pusher = {
  info: () => requests.get<IPusherProviderInfo>("/pusher/provider-info"),
};
const Signature = {
  getUploadUrl: () =>
    requests.get<{ destinationUrl: string; uploadHeaders: object }>(
      "/provider-signature/upload-url",
    ),
  putSignature: (url: string, image: any, headers: object) =>
    requests.putBinary(url, image, headers),
  setSignatureUploaded: (signatureUploaded: boolean) =>
    requests.post<{}>("/provider-signature/set-uploaded", { signatureUploaded }),
};
const Records = {
  list: (nextPageToken: string) =>
    requests.get<IVisitHistory>(
      `/provider-records/visit-history?${queryString.stringify({ nextPageToken })}`,
    ),
  search: (options: IEncounterSearchOptions) =>
    requests.get<IEncounterSearchResult>(
      `/provider-records/search-encounters?${queryString.stringify(options)}`,
    ),
  details: (providerID: string) =>
    requests.get<{ name: string }>(`/provider-records/details/${providerID}`),
  markClaimSubmitted: (encounterReference: string, submitted: boolean) =>
    requests.post<{}>("/provider-records/mark-claim-submitted", {
      encounterReference,
      submitted,
    }),
};
const Reporting = {
  PCRReport: () => requests.getCSV("/reporting/pcr-report-download", {}),
};
const Twilio = {
  getRoomToken: (name: string, room: string) =>
    requests.get(`/twilio/room-token?${queryString.stringify({ name, room })}`),
};
const UserRecords = {
  profile: (userID?: string) => requests.get<IPatientProfile>(`/user-records/profile/${userID}`),
  update: (userID: string, payload: IUpdateUserProfileRequest) =>
    requests.put<{}>(`/user-records/profile/${userID}`, payload),
  updateMedicalProfile: (userID: string, payload: IMedicalProfile) =>
    requests.put<{}>(`/user-records/medical-profile/${userID}`, payload),
  chart: (userID: string, pageToken?: string) =>
    requests.getWithQuery<IChartItems>(`/user-records/chart/${userID}`, { pageToken }),
  addChartItem: (userID: string, payload: { description: string; content: string }) =>
    requests.post<IUserChartEntry>(`/user-records/chart/${userID}`, payload),
  addChartFile: (userID: string, payload: IAddChartFileRequest) =>
    requests.post<IChartEntry>(`/user-records/chart/${userID}/item`, payload),
  editChartItem: (userID: string, payload: IEditChartItemRequest) =>
    requests.post<{}>(`/user-records/chart/${userID}/item/edit`, payload),
  strikeOutNote: (userID: string, payload: { key: { ID: string; Kind: string }; reason: string }) =>
    requests.post<{}>(`/user-records/chart/${userID}/item/strike`, payload),
  search: (query: { query: string; practice?: string }) =>
    requests.getWithQuery<I_IndexedUserPage>("/user-records/search", query),
  insuranceHistory: (userID: string) =>
    requests.get<I_InsuranceHistory>(`/user-records/profile/${userID}/insurance-history`),
  covidlHistory: (userID: string) =>
    requests.get<ICovidHistory>(`/user-records/covid-history/${userID}`),
  insuranceVerifications: () =>
    requests.get<I_InsuranceVerifications>("/user-records/insurance-verifications"),
  verifyInsurance: (userID: string, verified: boolean, payor: string) =>
    requests.post<{}>(`/user-records/verify-insurance`, {
      userID,
      verified,
      payor,
    }),
  getTytoCareLink: (encounterPayloadObj: {
    encounterID: string;
    userID: string;
    providerID: string;
  }) => {
    return requests.post<{ reviewURL: string }>(
      `/user-records/chart/tytoCareReviewLink`,
      encounterPayloadObj,
    );
  },
  createUser: (user: IUserCreationOptions) => {
    return requests.post<ICreateUser>(`/user-records/create-user`, user);
  },
  getPcrTests: () => requests.get<IpcrTests>(`/user-records/pcr-tests`),
};
const PCC = {
  patient: (userID: string) => requests.get<IPccPatientProfile>(`/pcc/patient/${userID}`),
  coverage: (userID: string) => requests.get<ICoverage>(`/pcc/coverage/${userID}`),
  observations: (userID: string) => requests.get<IObservationList>(`/pcc/observations/${userID}`),
  observationsLatest: (userID: string) =>
    requests.get<IObservationList>(`/pcc/observations/latest/${userID}`),
  observationsForDates: (userID: string, startDate: string, endDate: string, type?: string) =>
    requests.get<IObservationList>(
      `/pcc/observations/${userID}?startDate=${startDate}&endDate=${endDate}${
        !!type ? `&type=${type}` : ""
      }`,
    ),
  medications: (userID: string, status?: string) =>
    requests.get<IMedicationList>(`/pcc/medications/${userID}?status=${status || ""}`),
  allergies: (userID: string) => requests.get<IAllergiesInfo>(`/pcc/allergies/${userID}`),
  immunization: (userID: string) => requests.get<I_Immunizations>(`/pcc/immu/${userID}`),
  conditions: (userID: string) => requests.get<IConditions>(`/pcc/conditions/${userID}`),
  progressNotes: (userID: string) =>
    requests.get<IProgressNoteList>(`/pcc/progress-notes/${userID}`),
  progressNoteDetails: (progressNoteId: string, userID: string) =>
    requests.get<IProgressNote>(`/pcc/progress-note/${progressNoteId}?userID=${userID}`),
  advancedDirectives: (userID: string) =>
    requests.get<IAdvanceDirectivesConsentList>(`/pcc/advance-directives-consents/${userID}`),
  practitioner: (userID: string) => requests.get<IPractitioners>(`/pcc/practitioner/${userID}`),
  reSyncPCC: (userID: string, PracticeID: string) =>
    requests.post<{}>(`/pcc/emr-sync/${PracticeID}/${userID}/all`, {}),
  riskAssessment: (userID: string, startDate?: string, endDate?: string) =>
    requests.get(
      `/user-records/${userID}/risk-assessment?startDate=${startDate}&endDate=${endDate}`,
    ),
  assessments: (userID: string) => requests.get<IAssessmentList>(`/pcc/assessments/${userID}`),
};

const Admin = {
  reportEncounter: (payload: { encounterID: string }) =>
    requests.put<{ errorID: string } | {}>(`/provider-encounters/report`, payload),
};

const EMRNotifications = {
  get: () => requests.get<IEMRNotifications>(`/emr-hash/notifications`),
}

export default {
  Auditing,
  Authorization,
  Availability,
  Catalog,
  Encounters,
  Faxes,
  ImageUpload,
  Invitations,
  MedicalPractices,
  MedicalProfile,
  MobileVersions,
  Monitoring,
  NoteAddendum,
  Notifications,
  Prescriptions,
  Providers,
  Pusher,
  Signature,
  Records,
  Reporting,
  Twilio,
  UserRecords,
  PCC,
  HealthGorilla,
  Admin,
  EMRNotifications,
  api_root: API_ROOT,
  getToken: () => token,
  getKey: () => API_KEY,
  getEndpoint: () => API_ROOT,
  setToken: (_token) => {
    token = _token;
  },
};

import React, { Component } from "react";
import { connect } from "react-redux";

import { DISPLAY_RELOAD_BUTTON, REFRESH_TOKEN } from "../constants/actionTypes";
import api from "../api";
import { ICurrentUser } from "../constants/Types";
type Props = {
  currentTime: number;
  currentUser: ICurrentUser;
  refreshToken: Function;
  currentEMRHash: string;
  reloadButton: boolean;
  displayReloadButton: Function;
};

type State = {
  refreshTime: number | null;
};
const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    currentTime: state.time.currentTime,
    currentEMRHash: state.common.currentEMRHash,
    reloadButton: state.common.reloadButton,
  };
};

const mapDispatchToProps = (dispatch) => ({
  refreshToken: (payload) => dispatch({ type: REFRESH_TOKEN, payload }),
  displayReloadButton: (payload) => dispatch({ type: DISPLAY_RELOAD_BUTTON, payload})
});

const refreshTimeout = 60 * 8;
class TokenRefresher extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { refreshTime: null };
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentUser && !this.state.refreshTime) {
      this.setState({ refreshTime: this.props.currentTime + refreshTimeout });
    }
    if (this.props.currentUser && this.props.currentTime > this.state.refreshTime!) {


      this.props.refreshToken(api.Authorization.refreshToken());

      this.setState({ refreshTime: this.props.currentTime + refreshTimeout });
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenRefresher);

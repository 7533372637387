import React, { FC, useEffect, useRef, useState } from "react";
import "../../stylesheets/encounterMonitor.scss";
import { Tooltip } from "reactstrap";
import Info from "../../images/Info.svg";
import { seconds, minutes } from "../../utils";
import { timeLine } from "../../constants/Encounter";
const ToolTipComp = ({ event, idx, isToolTipOpen, toggle }) => {
  return (
    <Tooltip
      isOpen={isToolTipOpen("eventToolTip" + idx)}
      target={"eventToolTip" + idx}
      toggle={() => toggle("eventToolTip" + idx)}
      placement="right"
      style={{ backgroundColor: "#76adf1", color: "#000000" }}
    >
      Total count of encounters whos last timeline event is recorded as {event}
    </Tooltip>
  );
};
const sortTimeLineEvents = (events) => {
  events?.sort((a, b) => {
    return a?.count > b?.count ? -1 : 1;
  });
  return events;
};

type Props = {
  setMultiSelectInputValue: (value: string[], variants: { label: string; value: string }[]) => void;
  handleSearch: (value: string[], field: string) => void;
  data: any;
  searchParams?: string[];
};

const MonitorStatsTable: FC<Props> = ({
  setMultiSelectInputValue,
  handleSearch,
  data,
  searchParams,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [timelineEvents, setTimelineEvents] = useState([]);

  useEffect(() => {
    let sorted = sortTimeLineEvents(data?.timelineEvents) || [];
    setTimelineEvents(sorted);
  }, [data]);

  const toggle = (targetName) => {
    if (!tooltipOpen[targetName]) {
      setTooltipOpen({ ...tooltipOpen, [targetName]: true });
    } else {
      setTooltipOpen({ ...tooltipOpen, [targetName]: !tooltipOpen[targetName] });
    }
  };

  const isToolTipOpen = (targetName) => {
    return tooltipOpen[targetName] ? tooltipOpen[targetName] : false;
  };

  const handleClick = (value: string) => {
    let newStatusSearch = searchParams ? [...searchParams, value] : [value];

    setMultiSelectInputValue(newStatusSearch, timeLine);
    handleSearch(newStatusSearch, "latestVisitStatus");
  };

  const totalEncounters = data?.stats?.totalEncounters;

  const waitingTimeAvg = data?.stats?.waiting?.avgTime;
  const waitingTimeMinT = data?.stats?.waiting?.minTime;
  const waitingTimeMaxT = data?.stats?.waiting?.maxTime;

  const videoTimeAvg = data?.stats?.video?.avgTime;
  const videoTimeMinT = data?.stats?.video?.minTime;
  const videoTimeMaxT = data?.stats?.video?.maxTime;

  return (
    <div className="align-content-stretch encounter-search-menu encounter-monitor-table encounter-stats-menu">
      <div className="d-flex ">
        <div className="events-table-header d-flex">
          <div className="title-container d-flex flex-row">
            <h1 className="stat-title">Filtered Timeline Events</h1>
            <span className="title-subtext pl-3 pt-1">Based on your current search</span>
          </div>
          <div className="events-list p-2">
            {timelineEvents.map((e: { event: string; count: number }, idx) => {
              const event = e?.event;
              return (
                <div
                  key={`event-${idx}`}
                  className="m-1 timeline-item"
                  onClick={() => handleClick(event)}
                >
                  {event}
                  <span id={"eventToolTip" + idx}>
                    <Info />
                    <ToolTipComp
                      event={event}
                      idx={idx}
                      isToolTipOpen={isToolTipOpen}
                      toggle={toggle}
                    />
                  </span>{" "}
                  {`: ${e?.count}`}
                </div>
              );
            })}
          </div>
        </div>

        <div className="v-divider-line"></div>

        <div className="stats-table-header">
          <div className=" d-flex flex-row">
            <h1 className="stat-title">Filtered Encounter Stats</h1>
            <span className="title-subtext pl-3 pt-1">Based on your current search</span>
          </div>
          <div className="d-flex stats-item-list pt-2">
            <div className="stat-item total-encounters">
              <h2 className="w-100 stat-item-title">Total Encounters</h2>
              <div>
                <div className="stat">{totalEncounters ? totalEncounters : "--"}</div>
              </div>
            </div>
            <div className="stat-item avg-time-stat">
              <h2 className="w-100 stat-item-title">Avg. Waiting time</h2>
              <div className="d-flex flex-row">
                <div className="stat">
                  {waitingTimeAvg
                    ? `${minutes(waitingTimeAvg)}min ${seconds(waitingTimeAvg)}sec`
                    : "--"}
                </div>
                <div className="d-flex flex-column">
                  <span className="min-time">
                    Min:{" "}
                    {waitingTimeMinT
                      ? ` ${minutes(waitingTimeMinT)}min ${seconds(waitingTimeMinT)}sec`
                      : "--"}
                  </span>{" "}
                  <span className="max-time">
                    Max:{" "}
                    {waitingTimeMaxT
                      ? ` ${minutes(waitingTimeMaxT)}min ${seconds(waitingTimeMaxT)}sec`
                      : "--"}
                  </span>
                </div>
              </div>
            </div>

            <div className="stat-item avg-time-stat ">
              <h2 className="w-100 stat-item-title ">Avg. Video Time</h2>
              <div className="d-flex flex-row">
                <div className="stat">
                  {videoTimeAvg ? `${minutes(videoTimeAvg)}min ${seconds(videoTimeAvg)}sec` : "--"}
                </div>
                <div className="d-flex flex-column">
                  {" "}
                  <span className="min-time">
                    {" "}
                    Min:
                    {videoTimeMinT
                      ? ` ${minutes(videoTimeMinT)}min ${seconds(videoTimeMinT)}sec`
                      : "--"}
                  </span>{" "}
                  <span className="max-time">
                    Max:{" "}
                    {videoTimeMaxT
                      ? ` ${minutes(videoTimeMaxT)}min ${seconds(videoTimeMaxT)}sec`
                      : "--"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonitorStatsTable;

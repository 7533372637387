import React, { useEffect, Component, useState } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import Select from "react-select";
import PropTypes, { string, any } from "prop-types";

import DOB from "../Inputs/DOB";
import PhoneNumber from "../Inputs/PhoneNumber";
import ErrorList from "../ErrorList";
import {
  PERMISSION_MANAGE,
  PERMISSION_PAGE,
  PERMISSION_SEE_PATIENTS,
  PERMISSION_MEDICAL_DIRECTOR,
  PERMISSION_MONITOR,
  PERMISSION_PRESCRIBING_AGENT,
  PERMISSION_BILLING,
  PERMISSION_TELEMEDICINE_EXTENDER,
  PERMISSION_ADMIN,
  PERMISSION_SUPERVISING_PHYSICIAN,
  PERMISSION_SCRIBE,
  PERMISSION_RECENT_ENCOUNTER,
  PERMISSION_RECENT_ENCOUNTER_LIMITED,
  PERMISSION_NO_PHI,
  hasPermission,
} from "../../constants/Permissions";
import SupervisingPhysicianSelect from "./MedicalDirectorSelect";
import MedicalPracticeSelect from "./MedicalPracticeSelect";
import StateSelector, { STATES } from "../StateSelector";
import CheckToggle from "../CheckToggle";
const betaAccessLastName = ["Pleskach","Pandit","Elias"]
const isDigits = (s) => {
  const re = /^[0-9\b]+$/;
  return s === "" || re.test(s);
};

const FieldGroup = ({ label, fieldName, state, onChange, type, readOnly, autoCompleteOff, id }) => {
  const autoCompleteStatus = autoCompleteOff ? "new-value" : "on";

  return (
    <FormGroup>
      <Label>{label}</Label>
      {readOnly && (
        <span className="form-control form-control-plaintext"> {state[fieldName]} </span>
      )}
      {!readOnly && (
        <Input
          type={type || "text"}
          value={state[fieldName]}
          onChange={onChange(fieldName)}
          autoComplete={autoCompleteStatus}
          className={id || "text"}
        />
      )}
    </FormGroup>
  );
};

const stringToChoices = (strings, choices) => {
  const vs = strings || [];
  return vs.map((s) => {
    if (typeof s === "string") {
      return choices.find((v) => v.value === s);
    }
    return s;
  });
};

const isTelemedSelected = (provider) => {
  const isDisabled = hasPermission(provider.permissions, PERMISSION_TELEMEDICINE_EXTENDER);
  const isDirector = hasPermission(provider.permissions, PERMISSION_MEDICAL_DIRECTOR);

  const permissionChoices = [
    {
      value: PERMISSION_SCRIBE,
      label: "Scribe",
      isDisabled: isDisabled,
    },
    {
      value: PERMISSION_SEE_PATIENTS,
      label: "See Patients",
      isDisabled: isDisabled,
    },
    {
      value: PERMISSION_SUPERVISING_PHYSICIAN,
      label: "Supervising Physician",
      isDisabled: isDisabled,
    },
    {
      value: PERMISSION_PAGE,
      label: "Providers Page",
      isDisabled: isDisabled,
    },
    {
      value: PERMISSION_MANAGE,
      label: "Manage Providers",
      isDisabled: isDisabled,
    },
    {
      value: PERMISSION_MEDICAL_DIRECTOR,
      label: "Medical Director",
      isDisabled: isDisabled,
    },
    {
      value: PERMISSION_MONITOR,
      label: "Monitor Activity",
      isDisabled: isDisabled,
    },
    {
      value: PERMISSION_PRESCRIBING_AGENT,
      label: "DoseSpot Prescribing Agent",
      isDisabled: isDisabled,
    },
    { value: PERMISSION_BILLING, label: "Billing", isDisabled: isDisabled },
    {
      value: PERMISSION_TELEMEDICINE_EXTENDER,
      label: "Telemedicine Extender",
      isDisabled: provider.permissions.length,
    },
    {
      value: PERMISSION_ADMIN,
      label: "Admin",
      isDisabled: isDirector && isDisabled,
    },
    {
      value: PERMISSION_RECENT_ENCOUNTER,
      label: "Recent Encounter",
      isDisabled: isDisabled,
    },
    {
      value: PERMISSION_RECENT_ENCOUNTER_LIMITED,
      label:  "Recent Encounter Limited",
      isDisabled: isDisabled,
    },
    {
      value: PERMISSION_NO_PHI,
      label:  "No PHI",
      isDisabled: isDisabled,
    },
  ];
  return permissionChoices;
};

const languageChoices = [
  { value: "english", label: "English" },
  { value: "spanish", label: "Spanish" },
];

const demographicChoices = [
  { value: "child", label: "Children (0-13)" },
  { value: "adolescent", label: "Adolescents (14-17)" },
  { value: "adult", label: "Adults (18+)" },
];

const suffixChoices = [
  { value: "MD", label: "MD" },
  { value: "DO", label: "DO" },
  { value: "NP", label: "NP" },
  { value: "RN", label: "RN" },
  { value: "Other", label: "Other" },
];

const activeChoices = [
  { value: true, label: "Active" },
  { value: false, label: "Disabled" },
];

const OtherOption = { value: "Other", label: "Other" };

const geographicChoices = STATES;

/**
 * medicalDirectors and supervisingPhysicians are mutually inclusive.
 * Might change in future
 */

const ProviderForm = ({
  currentUser,
  onChange,
  provider,
  errors,
  isEditing,
  onResend,
  providerID,
  ...props
}) => {
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  useEffect(() => {
    if (provider.medicalDirectors) {
      const directors = Object.keys(provider.medicalDirectors);
      directors.forEach((director) => {
        if (provider.capabilities.geographic.indexOf(director) < 0) {
          onSuperVisingPhysicianChanged("medicalDirectors")({
            state: director,
            value: null,
          });
        }
      });
    }
  }, [provider.capabilities.geographic]);

  const onFieldChanged = (field) => {
    const handler = (event) => {
      onChange(field, event.target.value);
    };
    return handler;
  };

  const onSuffixSelected =
    (field) =>
    ({ value }) => {
      if (value === "Other") {
        setIsOtherSelected(true);
        value = "";
      } else {
        setIsOtherSelected(false);
      }
      onChange(field, value);
    };

  const onFieldChangedDigit = (field) => {
    const handler = (event) => {
      if (isDigits(event.target.value)) {
        onChange(field, event.target.value);
      }
    };
    return handler;
  };
  const onFieldToggle = (field) => (event) => {
    onChange(field, !event);
  };

  const onEnableToggle = (field) => (event) => {
    onChange(field, event);
  };

  const onBetaTestToggle = (field) => (event) => {
    onChange(field, event);
  };

  const onSuperVisingPhysicianChanged = (field: string) => (newValue: any) => {
    let directors = provider[field] ? provider[field] : {};
    let clone = { ...directors };
    if (newValue.value) {
      clone[newValue.state] = newValue.value;
    } else {
      delete clone[newValue.state];
    }
    onChange(field, clone);
  };
  const onSelectChanged = (field) => {
    const handler = (newValue) => {
      const nv = newValue.map((v) => v.value);
      onChange(field, nv);
    };
    return handler;
  };

  const onStatusChanged = (event) => {
    onChange("active", event.value);
  }

  const permissionChoices = isTelemedSelected(provider);
  const permissions = stringToChoices(provider.permissions, permissionChoices);

  const suffix = stringToChoices([provider.suffix], suffixChoices);

  const demographics = stringToChoices(provider.capabilities.demographic, demographicChoices);
  const linguistics = stringToChoices(provider.capabilities.linguistic, languageChoices);

  const geographics = stringToChoices(provider.capabilities.geographic, geographicChoices);

  const seePatientsError = !!provider.seePatientsErr
    ? ["Suffix required for seePatients permission."]
    : [];
  const allErrors = (errors || []).concat(seePatientsError);
  const active = activeChoices.find((i) => i.value === provider?.active);

  return (
    <div>
      {allErrors && <ErrorList errors={allErrors} />}

      <div className="d-flex">
        <div className="w-50">
          <Form className="provider-invite-form" autoComplete="off">
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <FieldGroup
              {...{
                label: "Prefix",
                fieldName: "prefix",
                state: provider,
                onChange: onFieldChanged,
                id: "qa-prefixDrp",
              }}
            />

            <FieldGroup
              {...{
                label: "First Name",
                fieldName: "firstName",
                state: provider,
                onChange: onFieldChanged,
                id: "qa-firstnameInput",
              }}
            />
            <FieldGroup
              {...{
                label: "Last Name",
                fieldName: "lastName",
                state: provider,
                onChange: onFieldChanged,
                id: "qa-lastnameInput",
              }}
            />

            <FormGroup>
              <Label>Suffix</Label>
              <Select
                value={!isOtherSelected ? suffix : OtherOption}
                options={suffixChoices}
                onChange={onSuffixSelected("suffix")}
                backspaceRemovesValue={false}
                id={"qa-suffixDrp"}
              />
            </FormGroup>
            {isOtherSelected && (
              <FieldGroup
                {...{
                  label: "",
                  fieldName: "suffix",
                  state: provider,
                  onChange: onFieldChanged,
                  id: "qa-suffixInput",
                }}
              />
            )}
            <FieldGroup
              {...{
                label: "Email",
                fieldName: "email",
                state: provider,
                onChange: onFieldChanged,
                type: "email",
                autoCompleteOff: true,
                id: "qa-emailInput",
              }}
            />
          {isEditing && (
            <FormGroup>
            <Label>Provider Status</Label>
            <Select
              value={active}
              options={activeChoices}
              onChange={(e) => onStatusChanged(e)}
              backspaceRemovesValue={false}
              id={"qa-statusDrp"}
            />
          </FormGroup>
          )}            
            <FormGroup>
              <Label> Date of Birth </Label>
              <DOB onChange={onFieldChanged("dateOfBirth")} value={provider.dateOfBirth} />
            </FormGroup>
            <FieldGroup
              {...{
                label: "Street",
                fieldName: "street",
                state: provider,
                onChange: onFieldChanged,
                id: "qa-streetinput",
              }}
            />
            <FieldGroup
              {...{
                label: "City",
                fieldName: "city",
                state: provider,
                onChange: onFieldChanged,
                id: "qa-cityInput",
              }}
            />
            <FormGroup>
              <Label> State </Label>
              <StateSelector onChange={onFieldChanged("state")} value={provider.state} />
            </FormGroup>
            <FieldGroup
              {...{
                label: "Zip",
                fieldName: "postalCode",
                state: provider,
                onChange: onFieldChanged,
                id: "qa-zipInput",
              }}
            />
            <FormGroup>
              <Label> Phone </Label>
              <PhoneNumber onChange={onFieldChanged("phone")} value={provider.phone} />
            </FormGroup>

            <FormGroup className="d-flex">
              <Label className="mr-2">Enable Texts Alerts:</Label>
              <CheckToggle
                className={"qa-sms-alert"}
                checked={!provider.disableAlerts}
                onChange={onFieldToggle("disableAlerts")}
              />
            </FormGroup>

            <FormGroup className="d-flex">
              <Label className="mr-2">Enable Toastify Notifications:</Label>
              <CheckToggle
                className={"qa-sms-alert"}
                checked={provider.enableToastifyNotifications}
                onChange={onEnableToggle("enableToastifyNotifications")}
              />
            </FormGroup>

            <FormGroup className="d-flex">
              <Label className="mr-2">Enable Browser Notifications:</Label>
              <CheckToggle
                className={"qa-sms-alert"}
                checked={provider.enableBrowserNotifications}
                onChange={onEnableToggle("enableBrowserNotifications")}
              />
            </FormGroup>

            <FieldGroup
              {...{
                label: "NPI Number",
                fieldName: "npiNumber",
                state: provider,
                onChange: onFieldChangedDigit,
                id: "qa-npi-input",
              }}
            />
            <FieldGroup
              {...{
                label: "DEA Number",
                fieldName: "deaNumber",
                state: provider,
                onChange: onFieldChanged,
                id: "qa-dea-input",
              }}
            />
            {isEditing && (
              <FieldGroup
                {...{
                  label: "DoseSpot ID",
                  fieldName: "doseSpotID",
                  state: provider,
                  onChange: onFieldChangedDigit,
                  id: "qa-dosespot-id-input",
                }}
              />
            )}

            <FormGroup>
              <Label>Permissions</Label>
              <Select
                value={permissions}
                options={permissionChoices}
                onChange={onSelectChanged("permissions")}
                backspaceRemovesValue={false}
                id="qa-permissions-menu"
                isMulti
              />
            </FormGroup>

            <FormGroup>
              <Label>Licensed States </Label>
              <Select
                value={geographics}
                options={geographicChoices}
                onChange={onSelectChanged("capabilities.geographic")}
                backspaceRemovesValue={false}
                id="qa-licensed-states"
                isMulti
                /*  menuIsOpen={true} */
              />
            </FormGroup>

            {!(
              hasPermission(provider.permissions, PERMISSION_MEDICAL_DIRECTOR) ||
              hasPermission(provider.permissions, PERMISSION_SUPERVISING_PHYSICIAN)
            ) &&
              hasPermission(provider.permissions, PERMISSION_SEE_PATIENTS) &&
              !(
                provider.permissions.length === 1 &&
                hasPermission(provider.permissions, PERMISSION_BILLING)
              ) && (
                <>
                  {geographics.map((stateFromSelect, index) => {
                    const stateName = stateFromSelect.label.slice(4);
                    let supervisingPhysician = provider.medicalDirectors
                      ? provider.medicalDirectors[stateFromSelect.value]
                      : "";
                    return (
                      <FormGroup key={index}>
                        <Label> {`${stateName} Supervising Physician`} </Label>
                        <SupervisingPhysicianSelect
                          value={supervisingPhysician}
                          providerState={stateFromSelect.value}
                          onChange={onSuperVisingPhysicianChanged("medicalDirectors")}
                          providerID={providerID || ""}
                          id="qa-medicalDirectorMenu"
                        />
                      </FormGroup>
                    );
                  })}
                </>
              )}
            <FormGroup>
              <Label> Medical Practices </Label>
              <MedicalPracticeSelect
                values={provider.medicalPractices || []}
                onChange={(nv) => onChange("medicalPractices", nv)}
                id="qa-practiceMenu"
              />
            </FormGroup>

            <FormGroup>
              <Label>Demographic Capabilities</Label>
              <Select
                value={demographics}
                options={demographicChoices}
                onChange={onSelectChanged("capabilities.demographic")}
                backspaceRemovesValue={false}
                isMulti
                id="qa-demographic-select"
              />
            </FormGroup>

            <FormGroup>
              <Label>Language Capabilities</Label>
              <Select
                value={linguistics}
                options={languageChoices}
                onChange={onSelectChanged("capabilities.linguistic")}
                backspaceRemovesValue={false}
                isMulti
                id="qa-language-select"
              />
            </FormGroup>
            {betaAccessLastName.includes(currentUser?.lastName) && (
              <FormGroup className="d-flex">
                <Label className="mr-2">Enable Beta Access:</Label>
                <CheckToggle
                  className={"qa-sms-alert"}
                  checked={provider.isBetaTester}
                  onChange={onBetaTestToggle("isBetaTester")}
                />
              </FormGroup>
            )}
          </Form>
        </div>
        <div className="w-50 ml-auto">
          {provider.invitePending && (
            <div className="d-flex flex-row-reverse pr-1">
              <Button color="primary" onClick={onResend}>
                Resend Invitation
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ProviderForm.propTypes = {
  onChange: PropTypes.func,
  provider: PropTypes.object,
  errors: PropTypes.array,
  isEditing: PropTypes.bool,
  onResend: PropTypes.func,
  providerID: PropTypes.string,
};

export default ProviderForm;
